import {
  Cancel as CancelIcon
} from '@mui/icons-material'
import { Box, Button, FormControlLabel, Modal, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { webApiService } from '../../../services'
import { useParams } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
import { useDetailsContext } from '../context/Details.context'
import { IAppStore } from '../../../models/store.model'
import { useSelector } from 'react-redux'

const CancelTradein = () => {
  const [data, setData] = useState<{ open: boolean, respuesta: string, loading: boolean, otro: string, valor: string, listCR: any[] }>({ open: false, respuesta: '', loading: false, otro: '', valor: '', listCR: [] })
  const { id } = useParams()
  const { ctx, saveCtx } = useDetailsContext()
  const userState = useSelector((store: IAppStore) => store.user)

  const handleClose = () => {
    setData({ ...data, open: false, respuesta: '', loading: false, otro: '', valor: '' })
  }

  const handleSave = () => {
    setData({ ...data, loading: true })
    webApiService.rechazarTradein({ cancel_reason: (data.respuesta === 'Otro') ? data.otro : data.respuesta, id_tradein: id, id_user: userState.id, client_value: data.valor }).then(() => {
      enqueueSnackbar('Trade-in Rechazado correctamente', { variant: 'success' })
      setData({ ...data, loading: false, respuesta: '', otro: '', valor: '', open: false })
      saveCtx({ ...ctx, reload: true })
    }).catch((error) => {
      enqueueSnackbar('Hubo un error al rechazar', { variant: 'error' })
      setData({ ...data, loading: false })
      console.log('error ==>', error)
    })
  }

  const loadData = () => {
    setData({ ...data, loading: true })
    webApiService.getAllCR().then((res: any) => {
      setData({ ...data, loading: false, listCR: res.data })
    }).catch((error) => {
      enqueueSnackbar(error.message, { variant: 'error' })
      console.log('error ==>', error)
      setData({ ...data, loading: false })
    })
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Button
        variant='contained'
        color='error'
        size='small'
        style={{ marginLeft: '5px' }}
        endIcon={<CancelIcon style={{ fontSize: '12px' }} />}
        onClick={() => { setData({ ...data, open: true }) }}
      >
        Rechazar Tradein
      </Button>
      <Modal open={data.open} onClose={handleClose}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            p: 2
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: '800px',
              backgroundColor: 'white',
              borderRadius: 2,
              textAlign: 'center',
              maxHeight: '90vh',
              overflowY: 'auto'
            }}
          >
            <Typography
              id='modal-title'
              variant='h6'
              component='h2'
              sx={{
                backgroundColor: '#333',
                color: '#fff',
                width: '100%',
                padding: 2
              }}
            >
              Rechazar Trade-in
            </Typography>
            <Box sx={{ mt: 2, mb: 2, p: 2 }}>
              <Box style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <Typography variant='h6' component='div' gutterBottom>
                  ¿Cual es el motivo de rechazo?
                </Typography>
                <RadioGroup
                  name='cancel_reason'
                  value={data.respuesta}
                  onChange={(e) => setData({ ...data, respuesta: e.target.value })}
                  row={false}
                >
                  {data.listCR
                    .filter((e: any) => e.type.toUpperCase() === 'USUARIO')
                    .map((e: any) => (
                      <FormControlLabel key={e.name} value={e.name} control={<Radio />} label={e.name} />
                    ))}
                  <FormControlLabel value='Otro' control={<Radio />} label='Otro' />
                </RadioGroup>
                <TextField
                  hidden={data.respuesta !== 'Otro'}
                  id='outlined-multiline-static'
                  label='Ingrese motivo'
                  value={data.otro}
                  multiline
                  rows={4}
                  onChange={(e) => { setData({ ...data, otro: e.target.value }) }}
                />
                <Typography variant='h6' component='div' gutterBottom>
                  ¿Indicar qué monto le gustaria al cliente interesado recibir por tu equipo?
                </Typography>
                <TextField
                  type='number'
                  id='outlined-multiline-static'
                  label='Ingrese monto'
                  value={data.valor}
                  onChange={(e) => { setData({ ...data, valor: e.target.value }) }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                backgroundColor: '#f1f1f1',
                width: '100%',
                padding: 2,
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
                display: 'flex',
                justifyContent: 'center',
                gap: 2
              }}
            >
              <Button
                variant='contained'
                size='small'
                color='error'
                onClick={handleClose}
              >
                Cerrar
              </Button>
              <Button variant='contained' color='primary' type='submit' disabled={data.valor === '' || data.respuesta === '' || data.loading || (data.otro === '' && data.respuesta === 'Otro')} onClick={handleSave}>
                {data.loading ? 'Enviando...' : 'Enviar'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default CancelTradein
