// import { FindInPage as FindInPageIcon } from '@mui/icons-material'
import { Alert, Badge, Box, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { IAppStore } from '../../../models/store.model'
import { StyledTableCell, StyledTableRow } from '../../../models/tableList'
import { webApiService } from '../../../services'
import { useDetailsContext } from '../context/Details.context'
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80vh',
  minHeight: '500px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
}

const Certificate: React.FC<any> = () => {
  const userState = useSelector((store: IAppStore) => store.user)
  const [data, setData] = useState<{ loading: boolean, openPdf: boolean }>({ loading: false, openPdf: false })
  const { ctx, saveCtx } = useDetailsContext()

  // const handleOpen = (action: string, certificate: string) => {
  //   saveCtx({ ...ctx, actionType: action, certificateType: certificate, openModal: true })
  // }

  const handleClose = () => {
    saveCtx({ ...ctx, openModal: false })
  }

  const handleConfirmCertificate = () => {
    setData({ ...data, loading: true })
    if (ctx.certificateType === 'theft') {
      saveCtx({ ...ctx, certificateTheft: ctx.actionType, openModal: false })
    } else if (ctx.certificateType === 'possession') {
      saveCtx({ ...ctx, certificatePossession: ctx.actionType, openModal: false })
    }
    webApiService.update({
      stage: 3,
      id: ctx.tradeinDetails.id,
      certificate_type: ctx.certificateType,
      certificate_value: ctx.actionType,
      user_id: userState.id
    })
      .then(() => {
        enqueueSnackbar('Registro actualizado', { variant: 'success' })
      })
      .catch((error) => {
        enqueueSnackbar('Hubo un error al intentar guardar el registro.', { variant: 'error' })
        console.log('error ==>', error)
      }).finally(() => {
        saveCtx({ ...ctx, openModal: false, reload: true })
        setData({ ...data, loading: false })
      })
  }

  return (
    <Card>
      <CardContent>
        <Typography
          variant='h6'
          component='div'
          gutterBottom
          style={{ borderBottom: '1px solid #f3f1f1', fontSize: '18px' }}
        >
          Certificado de Robo
        </Typography>
        {data.loading
          ? <CircularProgress />
          : (
              ctx.tradeinDetails.id_status >= 2 && ctx.tradeinDetails.serie !== null
                ? (
                  <>
                    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                      <Table aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            {/* <StyledTableCell align='left'>Nombre</StyledTableCell> */}
                            <StyledTableCell align='left'>Resultado</StyledTableCell>
                            <StyledTableCell align='right'>Estado</StyledTableCell>
                            {/* <StyledTableCell /> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <StyledTableRow
                            key={`${ctx.tradeinDetails.id}-theft`}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            {/* <StyledTableCell align='left'>Robo</StyledTableCell> */}
                            <StyledTableCell align='left'>
                              {/* <Tooltip title='Ver certificado'>
                                <Button variant='contained' size='small' startIcon={<FindInPageIcon />} onClick={() => { setData({ ...data, openPdf: true }) }} />
                              </Tooltip> */}
                              <Typography variant='body2' component='div' style={{ color: ctx.tradeinDetails.certificate_theft === 'VALID' ? 'green' : ctx.tradeinDetails.certificate_theft === 'INVALID' ? 'red' : '#000000de' }}>
                                {ctx.tradeinDetails.certificate_theft === 'VALID' ? 'Equipo no ha sido reportado como perdido o robado.' : ctx.tradeinDetails.certificate_theft === 'INVALID' ? 'Equipo esta reportado como perdido o robado.' : 'Esperando el resultado de la evaluación...'}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align='right'>
                              <Badge
                                badgeContent={
                            ctx.tradeinDetails.certificate_theft === 'VALID'
                              ? 'Válido'
                              : ctx.tradeinDetails.certificate_theft === 'INVALID'
                                ? 'Inválido'
                                : 'Sin validar'
                        }
                                sx={{
                                  '& .MuiBadge-badge': {
                                    backgroundColor:
                              ctx.tradeinDetails.certificate_theft === 'VALID'
                                ? '#2e7d32'
                                : ctx.tradeinDetails.certificate_theft === 'INVALID'
                                  ? '#f44336'
                                  : '#ff9800',
                                    color: '#fff',
                                    position: 'static',
                                    transform: 'none'
                                  }
                                }}
                              />
                            </StyledTableCell>
                            {/* <StyledTableCell align='right'>
                              {ctx.tradeinDetails.certificate_theft === null
                                ? (
                                  <Box display='flex' justifyContent='flex-end' gap={1}>
                                    <Tooltip title='Marcar como Inválido'>
                                      <Button
                                        variant='outlined'
                                        size='small'
                                        color='warning'
                                        startIcon={<WarningIcon />}
                                        onClick={() => handleOpen('INVALID', 'theft')}
                                        disabled={ctx.tradeinDetails.certificate_theft === 'INVALID' || ctx.tradeinDetails.certificate_possession === 'INVALID'}
                                      />
                                    </Tooltip>
                                    <Tooltip title='Marcar como Válido'>
                                      <Button
                                        variant='outlined'
                                        size='small'
                                        color='success'
                                        endIcon={<DoneIcon />}
                                        onClick={() => handleOpen('VALID', 'theft')}
                                        disabled={ctx.tradeinDetails.certificate_theft === 'INVALID' || ctx.tradeinDetails.certificate_possession === 'INVALID'}
                                      />
                                    </Tooltip>
                                  </Box>
                                  )
                                : (
                                    ''
                                  )}
                            </StyledTableCell> */}
                          </StyledTableRow>
                          {/* <StyledTableRow
                            key={`${ctx.tradeinDetails.id}-possession`}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <StyledTableCell align='left'>Tenencia del equipo</StyledTableCell>
                            <StyledTableCell align='left'>
                              <Tooltip title='Ver certificado'>
                                <Button variant='contained' size='small' startIcon={<FindInPageIcon />} onClick={() => { setData({ ...data, openPdf: true }) }} />
                              </Tooltip>
                            </StyledTableCell>
                            <StyledTableCell align='left'>
                              <Badge
                                badgeContent={
                            ctx.tradeinDetails.certificate_possession === 'VALID'
                              ? 'Válido'
                              : ctx.tradeinDetails.certificate_possession === 'INVALID'
                                ? 'Inválido'
                                : 'Sin validar'
                        }
                                sx={{
                                  '& .MuiBadge-badge': {
                                    backgroundColor:
                              ctx.tradeinDetails.certificate_possession === 'VALID'
                                ? '#2e7d32'
                                : ctx.tradeinDetails.certificate_possession === 'INVALID'
                                  ? '#f44336'
                                  : '#ff9800',
                                    color: '#fff',
                                    position: 'static',
                                    transform: 'none'
                                  }
                                }}
                              />
                            </StyledTableCell>
                            <StyledTableCell align='right'>
                              {ctx.tradeinDetails.certificate_possession === null
                                ? (
                                  <Box display='flex' justifyContent='flex-end' gap={1}>
                                    <Tooltip title='Marcar como Inválido'>
                                      <Button
                                        variant='outlined'
                                        size='small'
                                        color='warning'
                                        startIcon={<WarningIcon />}
                                        onClick={() => handleOpen('INVALID', 'possession')}
                                        disabled={ctx.tradeinDetails.certificate_theft === 'INVALID' || ctx.tradeinDetails.certificate_possession === 'INVALID'}
                                      />
                                    </Tooltip>
                                    <Tooltip title='Marcar como Válido'>
                                      <Button
                                        variant='outlined'
                                        size='small'
                                        color='success'
                                        endIcon={<DoneIcon />}
                                        onClick={() => handleOpen('VALID', 'possession')}
                                        disabled={ctx.tradeinDetails.certificate_theft === 'INVALID' || ctx.tradeinDetails.certificate_possession === 'INVALID'}
                                      />
                                    </Tooltip>
                                  </Box>
                                  )
                                : (
                                    ''
                                  )}
                            </StyledTableCell>
                          </StyledTableRow> */}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box mt={2}>
                      {ctx.tradeinDetails.certificate_theft === 'INVALID'
                        ? (
                          <Alert severity='error'>
                            Lo sentimos, no es posible realizar el Tradein.
                          </Alert>
                          )
                        : ctx.tradeinDetails.certificate_theft === null
                          ? (
                            <Alert severity='info'>Debe esperar la evaluación del dispositivo para obtener el resultado.</Alert>
                            )
                          : (
                            <Alert severity='success'>¡El dispositivo no tiene reportes de robo!.</Alert>
                            )}
                    </Box>
                  </>
                  )
                : (
                  <Alert severity='info'>
                    Se requiere el resultado de la evaluación técnica para obtener el certificado.
                  </Alert>
                  )
            )}
        <Dialog open={ctx.openModal} onClose={handleClose}>
          <DialogTitle>Confirmación</DialogTitle>
          <DialogContent>
            <DialogContentText>
              ¿Estás seguro que deseas marcar el certificado como {ctx.actionType === 'VALID' ? 'válido' : 'inválido'}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleConfirmCertificate} color='primary'>Aceptar</Button>
          </DialogActions>
        </Dialog>
        <Modal open={data.openPdf} onClose={() => { setData({ ...data, openPdf: false }) }}>
          <Box sx={style}>
            <iframe src='/demo.pdf' title='pdf' className='w-100 h-100' />
          </Box>
        </Modal>
      </CardContent>
    </Card>
  )
}

export default Certificate
