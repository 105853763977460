import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Box, Tooltip, Typography, styled, AppBar, Toolbar, IconButton, Avatar, TooltipProps } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import { useSelector } from 'react-redux'
import { IAppStore } from '../../../models/store.model'
import Logo from '../../../assets/img/images.png'
import LogoTradein from '../../../assets/img/tradein.png'
import { PrivateRoutes } from '../../../constants/routes'
import { useAppDispatch } from '../../../store/store'
import { resetUser } from '../../../store/user'
import RoleChildrenGuard from '../../../guards/RoleChildrenGuard'

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: theme.palette.common.black,
    color: 'white',
    boxShadow: theme.shadows[1],
    fontSize: 14
  }
}))

const Header = () => {
  const userState = useSelector((store: IAppStore) => store.user)
  const dispatch = useAppDispatch()
  const location = useLocation()

  return (
    <AppBar position='static' sx={{ backgroundColor: 'white', color: 'black', width: '100%', boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)!important' }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <div className='container-fluid p-0'>
          <div className='row'>
            <div className='col-12 col-md-4 py-4 py-md-0 d-flex justify-content-center justify-content-md-start'>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Link to={PrivateRoutes.HOME} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
                  <img src={Logo} alt='Logo' style={{ marginRight: '8px', height: '25px' }} />
                  <img src={LogoTradein} alt='Logo' style={{ marginRight: '8px', height: '30px' }} />
                </Link>
              </Box>
            </div>
            <div className='col-9 col-md-4 align-items-center d-flex justify-content-md-center'>
              <Box sx={{ display: 'flex', alignItems: 'center' }} gap={2}>
                <RoleChildrenGuard roles={[Number(process.env.REACT_APP_PERFIL_ADMIN), Number(process.env.REACT_APP_PERFIL_JEFETIENDA)]} PublicValidationFragment={null}>
                  <Link to={PrivateRoutes.HOME} className={location.pathname === PrivateRoutes.HOME ? 'text-black fw-bold' : 'text-black fw-bold text-decoration-none'}>HOME</Link>
                  <Link to={PrivateRoutes.PICKUP_FORM} className={location.pathname === PrivateRoutes.PICKUP_FORM ? 'text-black fw-bold' : 'text-black fw-bold text-decoration-none'}>FORMULARIO PICK UP</Link>
                </RoleChildrenGuard>

              </Box>
            </div>
            <div className='col-3 col-md-4 justify-content-end d-flex align-items-center'>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Avatar sx={{ backgroundColor: '#333' }}>{userState.name[0]}</Avatar>
                <div className='d-none d-md-block'>
                  <Box>
                    <Typography fontWeight='bold'>{userState.name} ({userState.name_role.replace('TI_', '')})</Typography>
                    <Typography variant='body2'>{userState.email}</Typography>
                  </Box>
                </div>

                <LightTooltip title='Cerrar sesión'>
                  <IconButton color='inherit' onClick={() => { dispatch(resetUser()) }}>
                    <LogoutIcon />
                  </IconButton>
                </LightTooltip>
              </Box>
            </div>
          </div>
        </div>

      </Toolbar>
    </AppBar>
  )
}

export default Header
