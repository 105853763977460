import MUIDataTable from 'mui-datatables'
import { useEffect, useState } from 'react'
import { webApiService } from '../../../../services'
import { UTCDateToCountryDate } from '../../../../utils/countryDate.util'
import { IAppStore } from '../../../../models/store.model'
import { useSelector } from 'react-redux'
import * as XLSX from 'xlsx'
import { IconButton, Tooltip } from '@mui/material'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'

const TableRetirados = () => {
  const [data, setData] = useState<{ loading: boolean, list: any[], seleccionados: any[], modificados: any[]}>({ loading: false, list: [], seleccionados: [], modificados: [] })
  const userState = useSelector((store: IAppStore) => store.user)

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'sbu',
      label: 'SBU',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'brand',
      label: 'MARCA',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'model',
      label: 'MODELO',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'serie',
      label: 'IMEI/SERIE',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'status',
      label: 'ESTADO',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'store',
      label: 'TIENDA',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'fecha_entregado',
      label: 'FECHA DE ENTREGA',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue : any) => {
          return UTCDateToCountryDate(value)
        }
      }
    },
    {
      name: 'custody_name',
      label: 'EN CUSTODIA POR',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'fecha_custodia',
      label: 'FECHA CUSTODIA',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue : any) => {
          return UTCDateToCountryDate(value)
        }
      }
    },
    {
      name: 'delivered_name',
      label: 'RETIRADO POR',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'seal',
      label: 'SELLO',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'seal2',
      label: 'SELLO 2',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'seal_weight',
      label: 'PESO CAJA',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'capacity',
      label: 'CAPACIDAD',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'fecha_retirado',
      label: 'FECHA RETIRADO',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue : any) => {
          return UTCDateToCountryDate(value)
        }
      }
    }
  ]

  const loadData = async () => {
    setData({ ...data, loading: true })

    await webApiService.getListTradeIn({ email: userState.email, poolId: String(process.env.REACT_APP_COGNITO_POOL_ID), listStatus: [7], id_role: userState.id_role }).then((res) => {
      console.log('🚀 ~ awaitwebApiService.getAllCustody ~ res:', res)
      setData({
        ...data,
        list: res.data,
        loading: false
      })
    })
      .catch((error) => {
        console.log('Error loading data:', error)
        setData({ ...data, loading: false })
      })
  }

  const downloadExcel = () => {
    const obj = data.list.map(ele => ({
      ID: ele.id,
      SBU: ele.sbu,
      MARCA: ele.brand,
      MODELO: ele.model,
      'IMEI/SERIE': ele.serie,
      ESTADO: ele.status,
      TIENDA: ele.store,
      'FECHA DE ENTREGA': UTCDateToCountryDate(ele.fecha_entregado),
      'EN CUSTODIA POR': ele.custody_name,
      'FECHA CUSTODIA': UTCDateToCountryDate(ele.fecha_custodia),
      'RETIRADO POR': ele.delivered_name,
      SELLO: ele.seal,
      'SELLO 2': ele.seal2,
      'PESO CAJA': ele.seal_weight,
      CAPACIDAD: ele.capacity,
      'FECHA RETIRADO': UTCDateToCountryDate(ele.fecha_retirado)

    }))

    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(obj)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Equipos retirados')
    const fileName = 'Equipos retirados.xlsx'
    XLSX.writeFile(workbook, fileName)
  }

  useEffect(() => {
    loadData()
    return () => {
      setData({ loading: false, list: [], seleccionados: [], modificados: [] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MUIDataTable
      title=''
      data={data.list}
      columns={columns}
      options={{
        selectableRowsHideCheckboxes: true,
        print: false,
        download: false,
        viewColumns: false,
        customToolbar: () => {
          return (
            <Tooltip title='Download Excel'>
              <IconButton
                sx={{ color: 'default', '&:hover': { color: '#1976D2' } }}
                onClick={downloadExcel}
              >
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>
          )
        }
      }}
    />
  )
}

export default TableRetirados
