import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Header from './components/Header'

const Layout: React.FC = () => {
  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 0)
    })
  }, [])

  return (
    <main className='main'>
      <Header />
      <div className='container-xxl'>
        <div className='content'>
          <nav className={scroll ? 'navbar navbar-light navbar-glass navbar-top navbar-expand navbar-glass-shadow' : 'navbar navbar-light navbar-glass navbar-top navbar-expand'} />
          <Outlet />
        </div>

      </div>
    </main>
  )
}

export default Layout
