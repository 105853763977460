import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import moment from 'moment'
import { FC } from 'react'
import LogoReuse from '../../../assets/img/logo-reuse-alargado.png'

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 10,
    width: '100%'
  },
  section: {
    margin: 10,
    display: 'flex',
    flexDirection: 'column'
  },
  sectionFirma: {
    margin: 10,
    padding: 10,
    display: 'flex',
    flexDirection: 'row'
  },
  bloqueFirma: {
    borderTop: 1,
    textAlign: 'center',
    fontSize: 14,
    fontFamily: 'Times-Bold',
    paddingTop: '5px'
  },
  image: {
    width: '100%'
  },
  viewer: {
    width: '100%'
  },
  table: {
    margin: 10,
    width: '100%',
    fontSize: '11px'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 8,
    paddingBottom: 8,
    wordBreak: 'break-all',
    whiteSpace: 'pre-wrap'
  },
  header: {
    backgroundColor: '#f7f7ff',
    borderTop: 'none'
  },
  bold: {
    fontWeight: 'bold'
  },
  col1: {
    width: '5%'
  },
  col2: {
    width: '30%'
  },
  col3: {
    width: '25%'
  },
  col4: {
    width: '20%'
  },
  col5: {
    width: '20%'
  },
  generalCol: {
    paddingLeft: 4,
    paddingRight: 4
  }
})

interface IOwnProps {
    firma: string
    detalle: any
    listado: any[]
}

const ManifiestoPDF : FC<IOwnProps> = ({ firma, detalle, listado }) => {
  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={{ ...styles.section, width: '120px' }}>
          <Image
            style={{ width: '120px' }}
            src={LogoReuse}
          />
        </View>
        <View style={styles.section}>
          <Text style={{ fontSize: 14, textAlign: 'left', marginBottom: '15px' }}>Estimado,</Text>
          <Text style={{ fontSize: 14 }}>Se dejan en custodia el siguiente listado de folios, acontinuación el detalle de equipos y quién recibio:</Text>
        </View>
        <View style={{ ...styles.section, marginBottom: '15px' }}>
          <View style={{ flexDirection: 'row', marginBottom: '5px', width: '100%', display: 'flex' }}>
            <Text style={{ fontSize: 14, width: 150, textAlign: 'left' }}>Receptor</Text>
            <Text style={{ fontSize: 14, fontWeight: 'bold' }}>: {detalle.name} {detalle.lastName} </Text>
          </View>
          <View style={{ flexDirection: 'row', marginBottom: '5px', width: '100%', display: 'flex' }}>
            <Text style={{ fontSize: 14, width: 150, textAlign: 'left' }}>Sello</Text>
            <Text style={{ fontSize: 14, fontWeight: 'bold' }}>: {detalle.seal}</Text>
          </View>
          <View style={{ flexDirection: 'row', marginBottom: '5px', width: '100%', display: 'flex' }}>
            <Text style={{ fontSize: 14, width: 150, textAlign: 'left' }}>Sello 2</Text>
            <Text style={{ fontSize: 14, fontWeight: 'bold' }}>: {detalle.seal2}</Text>
          </View>
          <View style={{ flexDirection: 'row', marginBottom: '5px', width: '100%', display: 'flex' }}>
            <Text style={{ fontSize: 14, width: 150, textAlign: 'left' }}>Fecha de recepción</Text>
            <Text style={{ fontSize: 14, fontWeight: 'bold' }}>: {moment(new Date()).format('DD/MM/YYYY - HH:mm:ss')}</Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={{ ...styles.row, ...styles.bold, ...styles.header }}>
            <Text style={styles.col1}>#</Text>
            <Text style={styles.col2}>Equipo</Text>
            <Text style={styles.col3}>Serie/IMEI</Text>
            <Text style={styles.col4}>Folio</Text>
            <Text style={styles.col5}>Tienda</Text>
          </View>
          {listado !== null && listado.map((row, i) => (
            <View key={i} style={styles.row} wrap={false}>
              <Text style={styles.col1}>
                <Text style={styles.bold}>{row.id}</Text>
              </Text>
              <Text style={{ ...styles.col2, ...styles.generalCol }}>{row.sbu + ' ' + row.brand + ' ' + row.model}</Text>
              <Text style={{ ...styles.col3, ...styles.generalCol }}>{row.serie}</Text>
              <Text style={{ ...styles.col4, ...styles.generalCol }}>{row.folio}</Text>
              <Text style={{ ...styles.col5, ...styles.generalCol }}>{row.store}</Text>
            </View>
          ))}
        </View>

        <View style={styles.sectionFirma}>
          <View style={{ width: '50%' }}>
            <Text />
          </View>
          <View style={{ width: '50%' }}>
            <Image
              style={styles.image}
              src={firma}
            />
            <Text style={styles.bloqueFirma}>Firma receptor</Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default ManifiestoPDF
