import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import store from './store/store'
import { SnackbarProvider } from 'notistack'
import { createTheme, ThemeProvider } from '@mui/material'

const theme = createTheme({
  components: {
    MUIDataTable: {
      styleOverrides: {
        paper: {
          boxShadow: 'none !important'
        }
      }
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          alignContent: 'center',
          padding: '8px 0px',
          backgroundColor: '#ababab',
          color: '#000'
        }
      }
    },
    MUIDataTableToolbarSelect: {
      styleOverrides: {
        root: {
          boxShadow: 'none !important'
        }
      }
    }
  }
})

ReactDOM.createRoot(document.getElementById('root') as Element).render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <SnackbarProvider maxSnack={5} autoHideDuration={4000}>
            <App />
          </SnackbarProvider>
        </BrowserRouter>
      </ThemeProvider>
    </ReduxProvider>
  </React.StrictMode>
)
