import { RequestQuote, CheckCircle, Cancel, VerifiedUser, AssignmentTurnedIn, HourglassBottom, Tour, ThumbDown, Info } from '@mui/icons-material'

export const Icon = (status: string) => {
  switch (status) {
    case 'COTIZADO':
      return (<RequestQuote style={{ color: '#e08fff' }} />)
    case 'APROBADO':
      return <CheckCircle style={{ color: '#fe7070' }} />
    case 'NO APROBADO':
      return <Cancel style={{ color: '#fe7070' }} />
    case 'VALIDADO':
      return <VerifiedUser style={{ color: '#fc9432' }} />
    case 'FIRMADO':
      return <AssignmentTurnedIn style={{ color: '#54c45e' }} />
    case 'ENTREGADO':
      return <HourglassBottom style={{ color: '#6db1ff' }} />
    case 'RETIRADO REUSE':
      return <Tour style={{ color: '#82755b' }} />
    case 'RECHAZADO':
      return <ThumbDown style={{ color: '#00c2a8' }} />
    default:
      return <Info color='disabled' />
  }
}
