import React from 'react'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import { useDetailsContext } from '../context/Details.context'

const Client: React.FC<any> = () => {
  const { ctx } = useDetailsContext()
  return (
    <Card sx={{ borderRadius: 1 }}>
      <CardContent>
        <Typography variant='h6' component='div' mb={2} gutterBottom style={{ borderBottom: '1px solid #f3f1f1', fontSize: '18px' }}>
          Cliente
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant='body1'><strong>Nombre:</strong> {ctx.tradeinDetails.name + ' ' + (ctx.tradeinDetails.lastname !== null ? ctx.tradeinDetails.lastname : '')}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='body1'><strong>Email:</strong> {ctx.tradeinDetails.email}</Typography>
          </Grid>
          <Grid item xs={4}>
            {ctx.tradeinDetails.rut !== null ? <Typography variant='body1'><strong>{String(process.env.REACT_APP_LABEL_RUT).toUpperCase()}: </strong> {ctx.tradeinDetails.rut} </Typography> : null}
          </Grid>
          <Grid item xs={4} hidden={ctx.tradeinDetails.store === null}>
            <Typography variant='body1'><strong>Tienda: </strong> {ctx.tradeinDetails.store} </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Client
