import AppleLogo from '../assets/img/logo-apple.png'
import SamsungLogo from '../assets/img/logo-samsung.png'
import GarminLogo from '../assets/img/logo-garmin.png'
import HpLogo from '../assets/img/logo-hp.png'
import DellLogo from '../assets/img/logo-dell.png'
import LenovoLogo from '../assets/img/logo-lenovo.png'
import HuaweiLogo from '../assets/img/logo-huawei.png'
import MotorolaLogo from '../assets/img/logo-motorola.png'
import VivoLogo from '../assets/img/logo-vivo.png'
import XiaomiLogo from '../assets/img/logo-xiaomi.png'

export const brandLogo = (brand: string) => {
  switch (brand.toLowerCase()) {
    case 'apple':
      return AppleLogo
    case 'samsung':
      return SamsungLogo
    case 'garmin':
      return GarminLogo
    case 'hp':
      return HpLogo
    case 'dell':
      return DellLogo
    case 'lenovo':
      return LenovoLogo
    case 'huawei':
      return HuaweiLogo
    case 'motorola':
      return MotorolaLogo
    case 'vivo':
      return VivoLogo
    case 'xiaomi':
      return XiaomiLogo
    default:
      return ''
  }
}
