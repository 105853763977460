import LoginImage from '../../assets/img/aufbau.jpg'
import LogoReuse from '../../assets/img/logo-reuse.png'
import { Box } from '@mui/material'
import { LoginForm } from './components'
import { FC } from 'react'

const LoginPage: FC = () => {
  return (
    <section className='h-100'>
      <div className='container-fluid h-100'>
        <div className='row h-100'>
          <div className='col-12 col-lg-7 d-none d-lg-flex justify-content-center align-items-center' style={{ backgroundColor: '#fafafd' }}>
            <img src={LoginImage} alt='' style={{ width: '70%', height: 'auto', objectPosition: 'center center', objectFit: 'cover' }} />
          </div>
          <div className='col-12  col-lg-5 bg-white h-100 d-flex justify-content-center align-items-center'>
            <Box sx={{ display: 'flex', width: '90%', flexDirection: 'column', maxWidth: '320px', margin: '0 auto' }}>
              <div className='text-center'><img src={LogoReuse} alt='' /></div>
              <p className='mt-3 h2 text-secondary text-center mt-xl-5'>Hola de nuevo!</p>
              <p className='small text-center mb-xl-5 text-secondary'><strong>Sistema de Trade-In Aufbau</strong></p>
              <LoginForm />
            </Box>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LoginPage
