import {
  ArrowBackIos as ArrowBackIosIcon,
  Done as DoneIcon
} from '@mui/icons-material'
import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDetailsContext } from '../context/Details.context'
import CancelTradein from './CancelTradein'
import { enqueueSnackbar } from 'notistack'
import { webApiService } from '../../../services'
import { IAppStore } from '../../../models/store.model'
import { useSelector } from 'react-redux'
import ResendCode from './ResendCode'
import { LoadingButton } from '@mui/lab'
import Recalculate from './Recalculate'
import CheckFeasibility from './CheckFeasibility/CheckFeasibility'

const Header: React.FC<any> = () => {
  const navigate = useNavigate()
  const handleBack = (): void => { navigate('/home') }
  const [loading, setLoading] = useState(false)
  const { ctx, saveCtx } = useDetailsContext()
  const { id } = useParams()
  const userState = useSelector((store: IAppStore) => store.user)

  const handleRecibir = () => {
    setLoading(true)
    webApiService.update({ id_tradein: id, id_user: userState.id, stage: 6 })
      .then((data) => {
        enqueueSnackbar('Estado actualizado a Entregado', { variant: 'success' })
        setLoading(false)
        console.log('data', data)
        saveCtx({ ...ctx, reload: true })
      })
      .catch((error: any) => {
        enqueueSnackbar('Hubo un error al reenviar el código', { variant: 'error' })
        setLoading(false)
        console.log('error ==>', error)
      })
  }

  return (
    <>
      <Typography variant='h5' component='div' mb={2} gutterBottom>
        <Button
          variant='text'
          onClick={handleBack}
          size='small'
          style={{ marginRight: '10px', textTransform: 'none' }}
        >
          <ArrowBackIosIcon style={{ fontSize: '12px' }} />
          <span style={{ marginRight: '4px' }}>VOLVER</span>
        </Button>
        {ctx.tradeinDetails !== null ? ctx.tradeinDetails.brand : null}
        {ctx.tradeinDetails !== null && ctx.tradeinDetails.model !== '' ? ' - ' + ctx.tradeinDetails.model : null}
      </Typography>
      <Box>
        <Grid item xs={12} display='flex' alignItems='end'>
          {ctx.tradeinDetails.id_status !== 7 &&
            (ctx.tradeinDetails.sbu === 'Smartphone' &&
            ctx.tradeinDetails.id_status === 6
              ? (
                <ResendCode />
                )
              : null)}
          {((ctx.tradeinDetails.id_status === 3 || (ctx.displayContract === 0 && ctx.tradeinDetails.id_status === 4)) && ctx.tradeinDetails.hasFiles === 1) && (
            <LoadingButton
              variant='contained'
              color='primary'
              size='small'
              style={{ marginLeft: '5px' }}
              endIcon={<DoneIcon style={{ fontSize: '15px' }} />}
              onClick={handleRecibir}
              loading={loading}
            >
              Recibir equipo
            </LoadingButton>
          )}
          {(ctx.tradeinDetails.recalculate === '1' && ctx.tradeinDetails.wasRecalculated === null && ctx.tradeinDetails.id_status !== 8) && (
            <Recalculate />
          )}
          {
            ctx.tradeinDetails.id_status === 8
              ? (
                <CheckFeasibility datos={ctx.tradeinDetails} />
                )
              : null
          }
          {ctx.tradeinDetails.id_status !== 5 && ctx.tradeinDetails.id_status !== 8 && (
            <CancelTradein />
          )}
        </Grid>
      </Box>
    </>
  )
}

export default Header
