import React from 'react'
import { useDetailsContext } from '../context/Details.context'
import { Info } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'

const SerieHelper: React.FC<any> = () => {
  const { ctx } = useDetailsContext()

  return (
    <Tooltip
      title={
        <div style={{ fontSize: '0.75rem' }}>
          {
            ctx.tradeinDetails.sbu === 'Notebook' && ctx.tradeinDetails.brand !== 'Apple'
              ? (
                <>
                  <span>Windows:</span>
                  <ul style={{ margin: 0, paddingLeft: '1.5rem' }}>
                    <li>Presiona Win + R, escribe cmd, y presiona Enter para abrir la ventana del Símbolo del sistema luego digita este comando y presiona Enter:</li>
                    <p style={{ color: 'orange' }}>wmic bios get serialnumber</p>
                  </ul>
                </>
                )
              : ctx.tradeinDetails.sbu === 'Notebook' && ctx.tradeinDetails.brand === 'Apple'
                ? (
                  <>
                    <span>MacOS:</span>
                    <ul style={{ margin: 0, paddingLeft: '1.5rem' }}>
                      <li>Puedes abrir la Terminal buscando "Terminal" en Spotlight luego digita este comando y presiona Enter:</li>
                      <p style={{ color: 'orange' }}>system_profiler SPHardwareDataType | grep "Serial Number"</p>
                    </ul>
                  </>
                  )
                : ctx.tradeinDetails.sbu === 'Tablet' && ctx.tradeinDetails.brand !== 'Apple'
                  ? (
                    <>
                      <span>Android:</span>
                      <ul style={{ margin: 0, paddingLeft: '0.5rem', listStyleType: 'none' }}>
                        <li>
                          <ol>
                            <li>Abre la aplicación de Configuración en el tablet.</li>
                            <li>Desplázate hacia abajo y selecciona "Acerca del teléfono" o "Acerca del dispositivo" (puede variar según la marca y modelo).</li>
                            <li>Busca y selecciona "Estado" o "Información del teléfono".</li>
                            <li>Allí deberías ver el número de serie de la tablet.</li>
                          </ol>
                        </li>
                      </ul>
                    </>
                    )
                  : ctx.tradeinDetails.sbu === 'Tablet' && ctx.tradeinDetails.brand === 'Apple'
                    ? (
                      <>
                        <span>iOS:</span>
                        <ul style={{ margin: 0, paddingLeft: '0.5rem', listStyleType: 'none' }}>
                          <li>
                            <ol>
                              <li>Abre la aplicación de Configuración en el iPad.</li>
                              <li>Toca "General" y luego "Información".</li>
                              <li>Desplázate hacia abajo para ver el número de serie.</li>
                            </ol>
                          </li>
                        </ul>
                      </>
                      )
                    : ctx.tradeinDetails.sbu === 'Smartwatch'
                      ? (
                        <>
                          <span>Gira a la parte trasera del Smartwatch para ver el numero de serie.</span>
                        </>
                        )
                      : null
            }
        </div>
                    }
      placement='top'
    >
      <IconButton
        className='export-button'
        color='primary'
        style={{
          position: 'absolute',
          top: -5,
          right: -5,
          borderRadius: '50%',
          minWidth: '20px',
          height: '20px',
          padding: 0
        }}
      >
        <Info />
      </IconButton>
    </Tooltip>
  )
}

export default SerieHelper
