import { Badge, Card, CardContent, List, ListItem, ListItemText, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { CurrencyFormat } from '../../../utils/Currency.utility'
import { StatusColor } from '../../../utils/StatusColor.utility'
import { useDetailsContext } from '../context/Details.context'
import { brandLogo } from '../../../utils/logoBrand.utility'

const Detail: React.FC<any> = () => {
  const { ctx } = useDetailsContext()
  console.log('ctx => ', ctx)
  return (
    <Card sx={{ borderRadius: 1 }}>
      <CardContent>
        <Typography variant='h6' component='div' mb={2} gutterBottom style={{ borderBottom: '1px solid #f3f1f1', fontSize: '18px' }}>
          Detalle
        </Typography>
        <List>
          <ListItem>
            {
              ctx.tradeinDetails.wasRecalculated === 1
                ? (<img src={brandLogo(String(ctx.tradeinDetails.make))} alt={ctx.tradeinDetails.make} style={{ maxHeight: '40px', maxWidth: '100%' }} />)
                : (<img src={brandLogo(String(ctx.tradeinDetails.brand))} alt={ctx.tradeinDetails.brand} style={{ maxHeight: '40px', maxWidth: '100%' }} />)
            }

          </ListItem>
          <ListItem>
            <ListItemText
              primary='Estado actual' secondary={
                <Badge
                  badgeContent={ctx.tradeinDetails.status}
                  sx={{
                    '& .MuiBadge-badge': {
                      backgroundColor: StatusColor(ctx.tradeinDetails.status),
                      color: '#fff',
                      position: 'static',
                      transform: 'none'
                    }
                  }}
                />
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText primary='Código de evaluación' secondary={ctx.tradeinDetails.login_code ?? 'Sin código'} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='Monto de descuento'
              secondary={
                <p>
                  <span
                    className={ctx.tradeinDetails.new_discount_amount !== null ? 'text-decoration-line-through me-2' : 'me-2'}
                  >
                    {CurrencyFormat(ctx.tradeinDetails.discount_amount)}
                  </span>
                  <span
                    hidden={ctx.tradeinDetails.new_discount_amount === null}
                  >
                    {CurrencyFormat(ctx.tradeinDetails.new_discount_amount)}
                  </span>
                </p>
  }
            />
          </ListItem>
          <ListItem>
            <ListItemText primary='Fecha de creación' secondary={moment(ctx.tradeinDetails.created_at).format('DD-MM-YYYY HH:mm')} />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  )
}

export default Detail
