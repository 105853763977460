import React, { useState } from 'react'
import { Alert, Box, Button, Card, CardContent, CircularProgress, Grid, TextField, Typography } from '@mui/material'
import { useDetailsContext } from '../context/Details.context'
import { webApiService } from '../../../services'
import { enqueueSnackbar } from 'notistack'
import SerieHelper from './SerieHelper'

const DeviceMobile: React.FC<any> = () => {
  const { ctx, saveCtx } = useDetailsContext()
  const [loading, setLoading] = useState(false)

  const handleSerieChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    saveCtx({ ...ctx, serie: event.target.value })
  }

  const handleSerieSave = (serie: string) => {
    setLoading(true)
    webApiService.update({ stage: 7, id: ctx.tradeinDetails.id, serie })
      .then(() => {
        enqueueSnackbar('Se actualizó el número de IMEI/serie.', { variant: 'success' })
      })
      .catch((error) => {
        enqueueSnackbar('Hubo un error al intentar guardar el número de IMEI/serie.', { variant: 'error' })
        console.log('error ==>', error)
      }).finally(() => {
        saveCtx({ ...ctx, reload: true })
        setLoading(false)
      })
  }
  // console.log('ctx', ctx.tradeinDetails)
  return (
    <>
      <Card sx={{ borderRadius: 1 }}>
        <CardContent>
          <Typography variant='h6' component='div' gutterBottom style={{ borderBottom: '1px solid #f3f1f1', fontSize: '18px' }}>
            Dispositivo
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='body1'><strong>Tipo:</strong> {ctx.tradeinDetails.sbu}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'><strong>Marca:</strong> {ctx.tradeinDetails.brand}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'><strong>Modelo:</strong> {ctx.tradeinDetails.model}</Typography>
            </Grid>
            {ctx.tradeinDetails.sbu === 'Notebook' && (
              <>
                <Grid item xs={12}>
                  <Typography variant='body1'><strong>CPU:</strong> {ctx.tradeinDetails.cpu}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1'><strong>Generación del CPU:</strong> {ctx.tradeinDetails.cpu_generation}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1'><strong>Pantalla:</strong> {ctx.tradeinDetails.screen}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1'><strong>Memoria RAM:</strong> {ctx.tradeinDetails.ram}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1'><strong>Almacenamiento:</strong> {ctx.tradeinDetails.capacity}</Typography>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Typography variant='body1'>
                <strong>N° de serie: </strong>
                {loading
                  ? (
                    <CircularProgress />
                    )
                  : (
                      ctx.tradeinDetails.id_status === 1 && ctx.tradeinDetails.serie === null
                        ? (
                          <Box position='relative' display='flex' alignItems='center' mt={2} mb={2}>
                            <Box position='relative' flexGrow={1} mr={2}>
                              <TextField
                                className='search-box'
                                label='Agregar serie'
                                variant='outlined'
                                size='small'
                                value={ctx.serie}
                                onChange={handleSerieChange}
                                style={{ width: '100%' }}
                              />
                              <SerieHelper style={{ position: 'absolute', top: 0, right: 0, transform: 'translate(50%, -50%)' }} />
                            </Box>
                            <Button
                              variant='contained'
                              size='small'
                              color='primary'
                              onClick={() => handleSerieSave(ctx.serie)}
                            >
                              Guardar
                            </Button>
                          </Box>
                          )
                        : (
                            ctx.tradeinDetails.serie
                          )
                    )}
              </Typography>
              {ctx.tradeinDetails.id_status === 1 && ctx.tradeinDetails.serie === null && (
                <Alert severity='warning' style={{ marginTop: '16px' }}>
                  Debe ingresar el N° de Serie del dispositivo
                </Alert>
              )}
            </Grid>

          </Grid>

        </CardContent>
      </Card>
    </>
  )
}

export default DeviceMobile
