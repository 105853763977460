import { Alert, Box, Card, CardContent, Typography, Modal, FormControl, RadioGroup, FormControlLabel, Radio, Button, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { webApiService } from '../../../services'
import { enqueueSnackbar } from 'notistack'
import { useDetailsContext } from '../context/Details.context'
import { useSelector } from 'react-redux'
import { IAppStore } from '../../../models/store.model'

const Checklist: React.FC<any> = () => {
  const { ctx, saveCtx } = useDetailsContext()
  const [loading, setLoading] = useState(false)
  const userState = useSelector((store: IAppStore) => store.user)
  const [questionsLength, setQuestionsLength] = useState(0)

  useEffect(() => {
    const filteredQuestions = ctx.questions.filter((e: any) => {
      const type = ctx.tradeinDetails.sbu === 'Notebook'
        ? 'COMPUTER'
        : ctx.tradeinDetails.sbu === 'Tablet'
          ? 'TABLET'
          : 'SMARTWATCH'
      console.log('🚀 ~ filteredQuestions ~ type:', type)
      return e.type.toUpperCase() === type
    })

    if (filteredQuestions.length !== questionsLength) {
      setQuestionsLength(filteredQuestions.length)
    }
  }, [ctx.questions, ctx.tradeinDetails.sbu, questionsLength])

  const handleChange = (question: string, answerText: string, score: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedAnswer = parseInt(event.target.value)
    const idQuestion = parseInt(event.target.name.split('_')[1])

    const answerIndex = ctx.answers.findIndex(answer => answer.id_question === idQuestion)

    if (answerIndex >= 0) {
      ctx.answers[answerIndex] = { id_question: idQuestion, question, text_answer: answerText, id_answer: selectedAnswer, score }
    } else {
      ctx.answers.push({ id_question: idQuestion, question, text_answer: answerText, id_answer: selectedAnswer, score })
    }

    saveCtx({
      ...ctx,
      answers: ctx.answers
    })
  }

  const handleSave = async () => {
    setLoading(true)
    const objChecklist = {
      stage: 5,
      id_tradein: ctx.tradeinDetails.id,
      array_answers: ctx.answers,
      id_user: userState.id
    }
    await webApiService.update(objChecklist)
      .then(() => {
        saveCtx({ ...ctx, reload: true, openModal: false })
        enqueueSnackbar('Checklist ingresado!', { variant: 'success' })
      })
      .catch((error) => {
        enqueueSnackbar('Hubo un error al intentar guardar el checklist.', { variant: 'error' })
        saveCtx({ ...ctx, reload: false })
        console.log('error ==>', error)
      }).finally(() => {
        setLoading(false)
      })
  }

  const getSelectedAnswer = (idQuestion: number) => {
    const answer = ctx.answers.find(answer => answer.id_question === idQuestion)
    return answer && answer.id_answer !== undefined ? answer.id_answer.toString() : ''
  }

  return (
    <Card>
      <CardContent>
        <Typography
          variant='h6'
          component='div'
          gutterBottom
          style={{ borderBottom: '1px solid #f3f1f1', fontSize: '18px' }}
        >
          Checklist
        </Typography>
        {loading
          ? <CircularProgress />
          : (
            <Box mt={2}>
              {ctx.tradeinDetails.serie !== null
                ? ctx.tradeinDetails.entry_form_status === 0
                  ? (
                    <>
                      <Box mt={2} mb={2}>
                        <Typography variant='body1' component='div' gutterBottom mb={2}>
                          El formulario permite validar el estado funcional del equipo a nivel de sistema y hardware.
                        </Typography>
                        <Button variant='contained' color='primary' onClick={() => saveCtx({ ...ctx, openModal: true })}>
                          Realizar Checklist
                        </Button>
                      </Box>
                      <Alert severity='info'>
                        Debe realizar el checklist para generar el contrato
                      </Alert>
                    </>
                    )
                  : (
                    <>
                      <Box mt={2} mb={2}>
                        <Button variant='contained' color='primary' onClick={() => saveCtx({ ...ctx, openModal: true })}>
                          Ver respuestas
                        </Button>
                      </Box>
                      <Alert severity='success'>Checklist ingresado con éxito.</Alert>
                    </>)
                : (
                  <Alert severity='warning'>Debe ingresar el numero de serie para realizar el Checklist</Alert>
                  )}
            </Box>
            )}
      </CardContent>
      <Modal open={ctx.openModal} onClose={() => saveCtx({ ...ctx, openModal: false })}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            p: 2
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: '800px',
              backgroundColor: 'white',
              borderRadius: 3,
              textAlign: 'center',
              maxHeight: '90vh',
              overflowY: 'auto',
              boxShadow: 5
            }}
          >
            <Typography
              id='modal-title'
              variant='h6'
              component='h2'
              sx={{
                backgroundColor: '#1976d2',
                color: '#fff',
                padding: 2,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3
              }}
            >
              {ctx.tradeinDetails.entry_form_status === 0 ? 'Checklist' : 'Respuestas Checklist'}
            </Typography>
            <Box sx={{ mt: 2, mb: 2, p: 2 }}>
              {
                ctx.tradeinDetails.entry_form_status === 0
                  ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                      {ctx.questions && ctx.questions
                        .filter((e: any) => {
                          const type = ctx.tradeinDetails.sbu === 'Notebook'
                            ? 'COMPUTER'
                            : ctx.tradeinDetails.sbu === 'Tablet'
                              ? 'TABLET'
                              : 'SMARTWATCH'
                          return e.type.toUpperCase() === type
                        })
                        .map((row: any, index: number) => (
                          <React.Fragment key={index}>
                            <Box sx={{ p: 2, textAlign: 'left', backgroundColor: '#f9f9f9', borderRadius: 2 }}>
                              <FormControl component='fieldset' margin='normal'>
                                <Typography variant='h6' component='div' gutterBottom>
                                  {row.question}
                                </Typography>
                                <RadioGroup
                                  name={'question_' + String(row.id_question)}
                                  value={getSelectedAnswer(row.id_question)}
                                  onChange={(e) => {
                                    const selectedAnswer = e.target.value
                                    const selectedAnswerText = JSON.parse(row.answers_json).find((answer: any) => answer.id_answer.toString() === selectedAnswer)?.answer || ''
                                    const selectedScore = JSON.parse(row.answers_json).find((answer: any) => answer.id_answer.toString() === selectedAnswer)?.score || ''
                                    handleChange(row.question, selectedAnswerText, Number(selectedScore), e as React.ChangeEvent<HTMLInputElement>)
                                  }}
                                >
                                  {JSON.parse(row.answers_json).map((e: any) => (
                                    <FormControlLabel
                                      key={e.id_answer}
                                      value={e.id_answer.toString()}
                                      control={<Radio />}
                                      label={e.answer}
                                    />
                                  ))}
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </React.Fragment>
                        ))}
                    </Box>
                    )
                  : (
                      ctx.entrys && ctx.entrys.map((entry: any, index: number) => (
                        entry.entrys.map((row: any, rowIndex: number) => {
                          return (
                            <React.Fragment key={`${index}-${rowIndex}`}>
                              <Box sx={{ p: 2, textAlign: 'left', backgroundColor: '#f9f9f9', borderRadius: 2 }}>
                                <Typography variant='h6' component='div' gutterBottom>
                                  {row.question}
                                </Typography>
                                <Typography variant='body1' component='div' gutterBottom>
                                  {row.text_answer}
                                </Typography>
                              </Box>
                            </React.Fragment>
                          )
                        })
                      )
                      )
                    )
              }
            </Box>
            <Box
              sx={{
                backgroundColor: '#f1f1f1',
                padding: 2,
                borderBottomLeftRadius: 3,
                borderBottomRightRadius: 3,
                display: 'flex',
                justifyContent: 'center',
                gap: 2,
                boxShadow: 1,
                position: 'fixed',
                bottom: 0,
                width: '100%',
                maxWidth: '800px',
                zIndex: 1000
              }}
            >
              <Button
                variant='contained'
                size='small'
                color='error'
                onClick={() => saveCtx({ ...ctx, openModal: false })}
              >
                Cerrar
              </Button>
              <Button variant='contained' color='primary' type='submit' disabled={loading || ctx.answers.length !== questionsLength} onClick={handleSave} style={{ display: ctx.tradeinDetails.entry_form_status === 0 ? 'block' : 'none' }}>
                {loading ? 'Enviando...' : 'Enviar'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Card>
  )
}

export default Checklist
