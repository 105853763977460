export const StatusColor = (status : string) => {
  switch (status) {
    case 'COTIZADO':
      return '#e08fff'
    case 'APROBADO':
      return '#fe7070'
    case 'NO APROBADO':
      return '#fe7070'
    case 'VALIDADO':
      return '#fc9432'
    case 'FIRMADO':
      return '#54c45e'
    case 'ENTREGADO':
      return '#6db1ff'
    case 'RETIRADO REUSE':
      return '#82755b'
    case 'RECHAZADO':
      return '#00c2a8'
    case 'CUSTODIA':
      return '#AAD500'
    default:
      return 'inerhit'
  }
}
