import { Box, Card, CardContent, Typography } from '@mui/material'
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@mui/lab'
import React from 'react'
import moment from 'moment'
import { StatusColor } from '../../../utils/StatusColor.utility'
import { Icon } from '../../../utils/Icons.utility'
import { useDetailsContext } from '../context/Details.context'

const History: React.FC<any> = () => {
  const { ctx } = useDetailsContext()
  return (
    <Card>
      <CardContent>
        <Typography variant='h6' component='div' gutterBottom style={{ borderBottom: '1px solid #f3f1f1', fontSize: '18px' }}>
          Historial de Estados
        </Typography>
        <Box sx={{ overflowX: 'auto', display: 'flex', flexDirection: 'column' }}>
          <Timeline position='alternate'>
            {ctx.statusHistory.map((row: any, index: number) => (
              <TimelineItem key={index}>
                <TimelineOppositeContent>
                  <Typography variant='caption' color='textSecondary'>
                    {moment(row.created_at).format('DD-MM-YYYY HH:mm')}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot sx={{ backgroundColor: StatusColor(row.status), color: '#fff !important' }}>
                    {Icon(row.status)}
                  </TimelineDot>
                  {index < ctx.statusHistory.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <Typography variant='body1'>{row.status}</Typography>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Box>
      </CardContent>
    </Card>
  )
}

export default History
