import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material'
import React, { FC, ReactNode } from 'react'

interface IOwnProps {
    loading:boolean
    color: string
    indicador: number
    estado: string
    textColor: string
    Icon: ReactNode
}

const StatusCard: FC<IOwnProps> = ({ loading, color, indicador, estado, textColor, Icon }) => {
  return (
    <Paper elevation={1} style={{ backgroundColor: color }} square={false}>
      <Box display='flex' alignItems='center' style={{ padding: '18px' }}>
        <Grid container>
          <Grid item md={9}>
            <Grid item md={12}>
              {loading
                ? <CircularProgress />
                : (
                  <Typography variant='h4' component='div'>
                    {indicador}
                  </Typography>
                  )}
            </Grid>
            <Grid item md={12}>
              <Typography variant='h6' component='div' color={textColor}>
                {estado}
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={3}>
            {Icon}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default StatusCard
