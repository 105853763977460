import React, { useState } from 'react'
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'
import * as AWS from 'aws-sdk'

import { LoadingButton } from '@mui/lab'
import { Alert, AlertTitle, Card, CardContent, Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputAdornment, TextField, Toolbar, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { CheckCircle, Cancel, VisibilityOff, Visibility } from '@mui/icons-material'
import { ModalProps } from '../../../../models/modals.model'
import { UserPool } from '../../../../utils/userPool.util'
import { Transition } from '../../../../components/Transition/Transition'
import { CloseIcon } from '../../../../assets/icons/Icons'

const ModalForgotPassword: React.FC<ModalProps> = ({ modal, setModal }) => {
  const [data, setData] = useState<{ loading: boolean, email: string, password: string, confirmPassword: string, stage: number, code: any, showPassword: boolean, showConfirmPassword: boolean }>({
    loading: false,
    email: '',
    password: '',
    confirmPassword: '',
    stage: 1,
    code: '',
    showPassword: false,
    showConfirmPassword: false
  })
  const { enqueueSnackbar } = useSnackbar()

  const handleClose = (): void => {
    setModal({ ...modal, visible: false })
  }

  // const handleChange = (e:any) => {
  //   e.preventDefault()
  // }

  const [passwordRequirements, setPasswordRequirements] = useState({
    minLength: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    hasSpecialChar: false
  })

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newPassword = e.target.value

    setPasswordRequirements({
      minLength: newPassword.length >= 8,
      hasUpperCase: /[A-Z]/.test(newPassword),
      hasLowerCase: /[a-z]/.test(newPassword),
      hasNumber: /[0-9]/.test(newPassword),
      hasSpecialChar: /[!@#$%^&*()_+{}[\]:;<>,.?~-]/.test(newPassword)
    })

    setData({ ...data, password: newPassword })
  }

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newConfirmPassword = e.target.value

    setData({ ...data, confirmPassword: newConfirmPassword })
  }

  const togglePasswordVisibility = (): void => {
    setData({ ...data, showPassword: !data.showPassword })
  }

  const toggleConfirmPasswordVisibility = (): void => {
    setData({ ...data, showConfirmPassword: !data.showConfirmPassword })
  }

  const CognitoIdentityServiceProvider = AWS.CognitoIdentityServiceProvider
  const client = new CognitoIdentityServiceProvider({
    apiVersion: '2016-04-19',
    region: 'us-west-2'
  })
  client.adminUpdateUserAttributes({
    UserAttributes: [{
      Name: 'phone_number_verified',
      Value: 'true'
    }, {
      Name: 'email_verified',
      Value: 'true'
    }, {
      Name: 'username',
      Value: data.email
    }
    ],
    UserPoolId: JSON.stringify(UserPool.getUserPoolId()),
    Username: data.email
  })

  const userPool = new CognitoUserPool({
    UserPoolId: String(process.env.REACT_APP_COGNITO_POOL_ID),
    ClientId: String(process.env.REACT_APP_COGNITO_WEB_CLIENT_ID)
  })
  const cognitoUser = new CognitoUser({
    Username: data.email,
    Pool: userPool
  })

  const setSuccess = (): void => {
    if (data.email === '') {
      enqueueSnackbar('No puedes dejar campos vacios', { variant: 'error' })
      return
    }
    const promise = new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: function (result) {
          resolve(result)
          setData({ ...data, stage: 2 })
          enqueueSnackbar('Se ha enviado un código de verificación a su correo para crear su nueva contraseña.', { variant: 'success' })
        },
        onFailure: function (err) {
          console.log(err.message)
          reject(err)
          if (err.message === 'Attempt limit exceeded, please try after some time.') {
            enqueueSnackbar('Excediste el número de intentos, por favor, intente en 1 hora ...', { variant: 'error' })
          }
        }
      })
    })
    console.log('🚀 ~ promise ~ promise:', promise)
    // return await promise
  }

  const resetPassword = (): void => {
    if (data.password === '' || data.confirmPassword === '') {
      enqueueSnackbar('No puedes dejar campos vacios', { variant: 'error' })
      return
    }
    if (data.password !== data.confirmPassword) {
      enqueueSnackbar('Las contraseñas no coinciden, por favor, intente nuevamente', { variant: 'error' })
      return
    }
    const promise = new Promise((resolve, _reject) => {
      cognitoUser.confirmPassword(data.code as string, data.confirmPassword, {
        onFailure: (err) => {
          console.log('onFailure : ', err)
          if (err.name === 'InvalidPasswordException') {
            enqueueSnackbar('La contraseña no cumple con los requisitos. Por favor, intente nuevamente.', { variant: 'error' })
          } else {
            enqueueSnackbar('Hubo un error.' + err.message, { variant: 'error' })
          }
        },
        onSuccess: (res) => {
          console.log('onSuccess : ', res)
          resolve(res)
          enqueueSnackbar('Nueva contraseña actualizada exitosamente, por favor, ingrese con sus nuevas credenciales', { variant: 'success' })
          handleClose()
          /*
          setTimeout(() => {
            window.location.replace('')
          }, 3000)
          */
        }
      })
    })
    console.log('🚀 ~ promise ~ promise:', promise)
  }

  return (
    <Dialog
      open={modal.visible}
      TransitionComponent={Transition}
      aria-labelledby='responsive-dialog-title'
      maxWidth='xs'
      fullWidth
    >
      <DialogTitle id='responsive-dialog-title' component='div' className='bg-azul p-0 text-white'>
        <Toolbar>
          <div className='col-12 text-center'>
            {data.stage === 1
              ? (
                <Typography variant='h6' component='div' className='color-title'>
                  <span>Recuperar Contraseña</span>
                </Typography>)
              : (
                <Typography variant='h6' component='div' className='color-title'>
                  <span>Actualizar Nueva Contraseña</span>
                </Typography>)}
          </div>
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#fff'
            }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </DialogTitle>
      <DialogContent dividers>
        <Card className='shadow-sm'>
          <CardContent>
            <div className='row gy-2'>
              <div className='col-12 my-3'>
                {data.stage === 1 && (
                  <FormControl fullWidth>
                    <TextField
                      margin='normal'
                      required
                      fullWidth
                      name='email'
                      value={data.email}
                      onChange={e => { setData({ ...data, email: e.target.value }) }}
                      label='Ingrese su email'
                      type='email'
                      id='email'
                      autoComplete='current-email'
                    />
                    <div className='col-12 text-center'>
                      <LoadingButton
                        type='submit'
                        fullWidth
                        onClick={setSuccess}
                        variant='contained'
                        sx={{ mt: 3, mb: 2, paddingTop: 1.3, paddingBottom: 1.3, width: 200 }}
                      >
                        Recuperar contraseña
                      </LoadingButton>
                    </div>
                  </FormControl>
                )}
                {data.stage === 2 && (
                  <FormControl fullWidth>
                    <TextField
                      margin='normal'
                      required
                      fullWidth
                      name='code'
                      value={data.code}
                      onChange={e => { setData({ ...data, code: e.target.value }) }}
                      label='Ingrese su código de verificación'
                      type='text'
                      id='code'
                      autoComplete='current-code'
                    />
                    <TextField
                      margin='normal'
                      required
                      fullWidth
                      name='password'
                      value={data.password}
                      onChange={handlePasswordChange}
                      label='Ingrese su nueva contraseña'
                      type={data.showPassword ? 'text' : 'password'}
                      id='password'
                      autoComplete='current-password'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton onClick={togglePasswordVisibility} edge='end'>
                              {data.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    <TextField
                      margin='normal'
                      required
                      fullWidth
                      name='confirmPassword'
                      value={data.confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      label='Confirme nueva contraseña'
                      type={data.showConfirmPassword ? 'text' : 'password'}
                      id='confirmPassword'
                      autoComplete='current-confirmPassword'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton onClick={toggleConfirmPasswordVisibility} edge='end'>
                              {data.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    <Alert severity='info'>
                      <AlertTitle>Tu contraseña debe incluir al menos:</AlertTitle>
                      <div>
                        {passwordRequirements.minLength ? <CheckCircle color='success' className='me-1' /> : <Cancel color='error' className='me-1' />}
                        <span>Mínimo 8 caracteres</span>
                      </div>
                      <div>
                        {passwordRequirements.hasUpperCase ? <CheckCircle color='success' className='me-1' /> : <Cancel color='error' className='me-1' />}
                        <span>Una mayúscula</span>
                      </div>
                      <div>
                        {passwordRequirements.hasLowerCase ? <CheckCircle color='success' className='me-1' /> : <Cancel color='error' className='me-1' />}
                        <span>Una minúscula</span>
                      </div>
                      <div>
                        {passwordRequirements.hasNumber ? <CheckCircle color='success' className='me-1' /> : <Cancel color='error' className='me-1' />}
                        <span>Un número</span>
                      </div>
                      <div>
                        {passwordRequirements.hasSpecialChar ? <CheckCircle color='success' className='me-1' /> : <Cancel color='error' className='me-1' />}
                        <span>Un caractér especial</span>
                      </div>
                    </Alert>
                    <div className='col-12 text-center'>
                      <LoadingButton
                        type='submit'
                        fullWidth
                        onClick={resetPassword}
                        variant='contained'
                        sx={{ mt: 3, mb: 2, paddingTop: 1.3, paddingBottom: 1.3, width: 200 }}
                      >
                        Guardar
                      </LoadingButton>
                    </div>
                  </FormControl>
                )}
              </div>
            </div>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>

  )
}

export default ModalForgotPassword
