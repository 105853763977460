import { useEffect, type FC } from 'react'

const Redirect: FC<{ url: string }> = ({ url }) => {
  useEffect(() => {
    window.location.href = url
  }, [url])

  return <h5>Redireccionando...</h5>
}

export default Redirect
