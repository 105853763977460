import { ArrowForwardIos, AssignmentTurnedIn, Cancel, CheckCircle, HourglassBottom, Info, RequestQuote, Search, ThumbDown, Tour, VerifiedUser } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Badge, Box, Button, ButtonBase, Card, CardContent, CardHeader, CircularProgress, ClickAwayListener, Divider, Grid, IconButton, MenuItem, Pagination, Paper, Popper, Select, Stack, Tab, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Tabs, TextField, Tooltip, Typography } from '@mui/material'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import XLSX from 'xlsx-js-style'
import XLSIcon from '../../../assets/img/icons8-exportar-excel-48.png'
import { IAppStore } from '../../../models/store.model'
import { LightTooltip, StyledTableCell, StyledTableRow } from '../../../models/tableList'
import { webApiService } from '../../../services'
import { CurrencyFormat } from '../../../utils/Currency.utility'
import { StatusColor } from '../../../utils/StatusColor.utility'
import StatusCard from './StatusCard'
import TableCustody from './TableCustody/TableCustody'
import TableRecibidos from './TableRecibidos/TableRecibidos'
import TableRetirados from './TableRetirados/TableRetirados'
import { UTCDateToCountryDate } from '../../../utils/countryDate.util'

interface IOwnProps {
  mainList: any[];
  listIndicadores: any[];
  listByBrands: any[];
  listByModels: any[];
  listByRejected: any[];
  listByMonth: any[];
  showTopTen: boolean;
  loading: boolean;
  table: {
    search: string;
    page: number;
    rowsPerPage: number;
  };
  currentTab: number;
  page: number;
  pageCoticed: number;
  rowsPerPage: number;
  rowsPerPageCoticed: number;
  hover: { [key: number]: boolean };
  anchorEl: any;
  openFind: boolean;
}

const TableList = () => {
  const userState = useSelector((store: IAppStore) => store.user)
  const [data, setData] = useState<IOwnProps>({
    mainList: [],
    listIndicadores: [],
    listByBrands: [],
    listByModels: [],
    listByRejected: [],
    listByMonth: [],
    showTopTen: true,
    loading: false,
    table: {
      search: '',
      page: 0,
      rowsPerPage: 10
    },
    currentTab: 0,
    page: 0,
    pageCoticed: 0,
    rowsPerPage: 5,
    rowsPerPageCoticed: 5,
    hover: {},
    anchorEl: null,
    openFind: false
  })
  console.log('data => ', data)

  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const searchBoxRef = useRef(null)

  const loadData = (): void => {
    setData({ ...data, loading: true })
    Promise.all([
      webApiService.getListTradeIn({ email: userState.email, poolId: String(process.env.REACT_APP_COGNITO_POOL_ID), listStatus: [1, 2, 3, 4, 5, 6, 7, 8, 9], id_role: userState.id_role }),
      webApiService.getCountStatus()
    ])
      .then(([list, indicadores]) => {
        setData({
          ...data,
          mainList: list.data,
          listIndicadores: indicadores.data,
          loading: false
        })
      })
      .catch((error) => {
        console.log('Error loading data:', error)
        setData({ ...data, loading: false })
      })
  }

  const handleOpenFind = (event: any) => {
    setData({ ...data, anchorEl: data.anchorEl ? null : event.currentTarget, openFind: true })
  }

  const handleCloseFind = () => {
    setData({ ...data, openFind: false, anchorEl: null })
  }

  const handleMouseEnter = (rowId: number) => {
    setData(prevData => ({
      ...prevData,
      hover: { ...prevData.hover, [rowId]: true }
    }))
  }

  const handleMouseLeave = (rowId: number) => {
    setData(prevData => ({
      ...prevData,
      hover: { ...prevData.hover, [rowId]: false }
    }))
  }

  const triggerDetails = (row: any): void => {
    navigate(`/details/${row.id}`, { state: { row } })
  }

  // const handleEntregar = (row: any): void => {
  //   setData({ ...data, loading: true })
  //   webApiService.entregar({ imei: row.imei, operation: 0 }).then(async res => {
  //     enqueueSnackbar('Trade-In Completado', { variant: 'success' })
  //     const rows = await webApiService.getList().then(res => res.items)
  //     setData({ ...data, mainList: rows, loading: false, showTopTen: true })
  //   }).catch((error: any) => {
  //     console.log('getProfile error:', error)
  //     enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' })
  //     setData({ ...data, loading: false })
  //   })
  // }

  const toggleData = () => {
    setData((prevData) => ({
      ...prevData,
      showTopTen: !prevData.showTopTen
    }))
  }

  const topCoticed = data.listByModels.slice(0, 5)
  const allCoticed = data.listByModels

  const chartData = data.showTopTen ? topCoticed : allCoticed

  const optionsModels = {
    credits: {
      enabled: false
    },
    chart: {
      height: 350,
      backgroundColor: 'rgba(0,0,0,0)',
      type: 'column'
    },
    title: false,
    exporting: {
      enabled: false
    },
    legend: {
      verticalAlign: 'top',
      itemStyle: {
        color: '#000000'
      },
      itemHoverStyle: {
        color: '#020202'
      }
    },
    yAxis: {
      title: false,
      labels: {
        style: {
          color: '#000000'
        }
      }
    },
    xAxis: {
      categories: chartData.map(item => item.model),
      labels: {
        style: {
          color: '#000000'
        }
      }
    },
    annotations: {
      visible: false
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true,
          symbol: 'circle'
        }
      }
    },
    series: [{
      name: 'Cantidad de cotizaciones',
      data: chartData.map((item) => item.count)
    }]
  }

  const optionsBrands = {
    credits: {
      enabled: false
    },
    chart: {
      height: 350,
      backgroundColor: 'rgba(0,0,0,0)',
      type: 'area'
    },
    title: false,
    exporting: {
      enabled: false
    },
    legend: {
      verticalAlign: 'top',
      itemStyle: {
        color: '#000000'
      },
      itemHoverStyle: {
        color: '#020202'
      }
    },
    yAxis: {
      title: false,
      labels: {
        style: {
          color: '#000000'
        }
      }
    },
    xAxis: {
      categories: data.listByBrands.map(item => item.brand),
      labels: {
        style: {
          color: '#000000'
        }
      }
    },
    annotations: {
      visible: false
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true,
          symbol: 'circle'
        }
      }
    },
    series: [{
      name: 'Cantidad de cotizaciones',
      data: data.listByBrands.map((item) => item.count)
    }]
  }

  const optionsRejected = {
    credits: {
      enabled: false
    },
    chart: {
      height: 350,
      backgroundColor: 'rgba(0,0,0,0)',
      type: 'area'
    },
    title: false,
    exporting: {
      enabled: false
    },
    legend: {
      verticalAlign: 'top',
      itemStyle: {
        color: '#000000'
      },
      itemHoverStyle: {
        color: '#020202'
      }
    },
    yAxis: {
      title: false,
      labels: {
        style: {
          color: '#000000'
        }
      }
    },
    xAxis: {
      categories: data.listByRejected.map(item => item.cancel_reason),
      labels: {
        style: {
          color: '#000000'
        }
      }
    },
    annotations: {
      visible: false
    },
    plotOptions: {
      area: {
        color: '#FF0000'
      },
      series: {
        marker: {
          enabled: true,
          symbol: 'circle'
        }
      }
    },
    series: [{
      name: 'N° veces por motivo de rechazo',
      data: data.listByRejected.map((item) => item.count)
    }
    ]
  }

  const optionsCoticedByMonth = {
    credits: {
      enabled: false
    },
    chart: {
      height: 350,
      backgroundColor: 'rgba(0,0,0,0)',
      type: 'areaspline'
    },
    title: false,
    exporting: {
      enabled: false
    },
    legend: {
      verticalAlign: 'top',
      itemStyle: {
        color: '#000000'
      },
      itemHoverStyle: {
        color: '#020202'
      }
    },
    yAxis: {
      title: false,
      labels: {
        style: {
          color: '#000000'
        }
      }
    },
    xAxis: {
      categories: data.listByMonth.map(item => item.mes + '-' + item.año),
      labels: {
        style: {
          color: '#000000'
        }
      }
    },
    annotations: {
      visible: false
    },
    plotOptions: {
      areaspline: {
        color: '#5651c8',
        marker: {
          enabled: true,
          symbol: 'circle'
        }
      },
      series: {
        marker: {
          enabled: true,
          symbol: 'circle'
        }
      }
    },
    series: [{
      name: 'Cantidad de cotizaciones',
      data: data.listByMonth.map((item) => item.contador)
    }]
  }

  const ExportToExcelButton: any = ({ data, stage }: any) => {
    const handleExport = () => {
      let reorderedData
      const now = new Date()
      const formattedDate = `${now.getDate()}_${now.getMonth() + 1}_${now.getFullYear()}`
      if (stage === 'modelos_cotizados') {
        const desiredOrder = ['index', 'brand', 'model', 'count']
        const headerMapping: { [key: string]: string } = {
          index: '#',
          brand: 'Marca',
          model: 'Modelo',
          count: 'Veces cotizado'
        }
        reorderedData = data.map((item: any, index: number) => {
          const reorderedItem: any = {}
          desiredOrder.forEach((key: string) => {
            if (key === 'index') reorderedItem[headerMapping[key]] = index + 1
            else reorderedItem[headerMapping[key]] = item[key]
          })
          return reorderedItem
        })
      } else if (stage === 'listado_principal') {
        const desiredOrder = ['index', 'name', 'email', 'sbu', 'brand', 'model', 'serie', 'discount_amount', 'status', 'cancel_reason', 'last_status_date']
        const headerMapping: { [key: string]: string } = {
          index: '#',
          name: 'Nombre y apellido',
          email: 'Email',
          sbu: 'Dispositivo',
          brand: 'Marca',
          model: 'Modelo',
          serie: 'Serie/IMEI',
          discount_amount: 'Monto descuento',
          status: 'Estado',
          cancel_reason: 'Motivo de rechazo',
          last_status_date: 'Fecha'
        }

        reorderedData = data.map((item: any, index: number) => {
          const reorderedItem: any = {}
          desiredOrder.forEach((key: string) => {
            if (key === 'index') reorderedItem[headerMapping[key]] = index + 1
            else if (key === 'name') {
              reorderedItem[headerMapping[key]] = item[key] ? `${item[key]} ${item.lastname || ''}`.trim() : 'Sin dato'
            } else if (key === 'cancel_reason') {
              reorderedItem[headerMapping[key]] = item.cancel_reason !== null && item.cancel_reason.trim() !== '' ? item.cancel_reason : item[key]
            } else if (key === 'discount_amount') {
              reorderedItem[headerMapping[key]] = CurrencyFormat(item[key])
            } else if (key === 'last_status_date') {
              reorderedItem[headerMapping[key]] = UTCDateToCountryDate(item[key])
            } else {
              reorderedItem[headerMapping[key]] = item[key]
            }
          })
          return reorderedItem
        })
      } else {
        reorderedData = data
      }

      const ws = XLSX.utils.json_to_sheet(reorderedData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Modelos Cotizados')

      // Aplico color de fondo al header
      const headerRange = XLSX.utils.decode_range(ws['!ref'] || '')
      for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C })
        if (!ws[cellAddress]) continue
        ws[cellAddress].s = {
          fill: { fgColor: { rgb: '000000' } },
          font: { color: { rgb: 'ffffff' }, bold: true }
        }
      }

      // Activo los filtros en las columnas
      ws['!autofilter'] = { ref: XLSX.utils.encode_range(headerRange) }

      // Aplico colores especificos a los campos de estado
      if (stage === 'listado_principal') {
        const stateColIndex = Object.keys(reorderedData[0]).indexOf('Estado')
        if (stateColIndex !== -1) {
          for (let R = 1; R <= headerRange.e.r; ++R) {
            const cellAddress = XLSX.utils.encode_cell({ r: R, c: stateColIndex })
            const cellValue = ws[cellAddress] ? ws[cellAddress].v : null
            console.log('🚀 ~ handleExport ~ cellValue:', cellValue)
            if (cellValue) {
              let bgColor = 'ffffff'
              if (cellValue === 'Rechazado'.toUpperCase()) bgColor = 'd73a4a'
              else if (cellValue === 'Cotizado'.toUpperCase()) bgColor = '388e3c'
              else if (cellValue === 'Aceptado'.toUpperCase()) bgColor = '9c27b0'
              else if (cellValue === 'Aprobado'.toUpperCase()) bgColor = '1976d2'
              else if (cellValue === 'Firmado'.toUpperCase()) bgColor = '00838f'
              else if (cellValue === 'Entregado'.toUpperCase()) bgColor = 'f57c00'
              else if (cellValue === 'NO APROBADO'.toUpperCase()) bgColor = 'd32f2f'
              else if (cellValue === 'VALIDADO'.toUpperCase()) bgColor = 'fb8c00'
              else if (cellValue === 'RETIRADO REUSE'.toUpperCase()) bgColor = '7051a0'
              else if (cellValue === 'CUSTODIA'.toUpperCase()) bgColor = 'AAD500'
              ws[cellAddress].s = {
                fill: { fgColor: { rgb: bgColor } },
                font: { color: { rgb: 'ffffff' } }
              }
            }
          }
          // Defino ancho especifico para cada columna
          ws['!cols'] = [
            { wpx: 30 },
            { wpx: 200 },
            { wpx: 100 },
            { wpx: 250 },
            { wpx: 100 },
            { wpx: 120 },
            { wpx: 100 },
            { wpx: 200 }
          ]
        }
      } else if (stage === 'modelos_cotizados') {
        // Defino ancho especifico para cada columna
        ws['!cols'] = [
          { wpx: 30 },
          { wpx: 100 },
          { wpx: 250 },
          { wpx: 100 }
        ]
      }

      XLSX.writeFile(wb, `Export_${stage}_${formattedDate}.xlsx`)
      enqueueSnackbar('Exportación completada con éxito', { variant: 'success' })
    }

    return (
      <ButtonBase aria-label='XLSX Export' component='a' onClick={handleExport}>
        <LightTooltip title='Descargar tabla en Excel'>
          <img src={XLSIcon} alt='XLSX Export' style={{ width: '30px' }} />
        </LightTooltip>
      </ButtonBase>
    )
  }

  const handleChangePageCoticed = (event: any, newPage: number) => {
    setData({ ...data, pageCoticed: newPage })
  }

  const handleChangeRowsPerPageCoticed = (event: any) => {
    setData({ ...data, rowsPerPageCoticed: parseInt(event.target.value, 5), pageCoticed: 0 })
  }

  const matchesSearchTerm = (row: any): boolean => {
    const estado = row.status.toLowerCase()
    const sbu = row.sbu.toLowerCase()
    const modelo = row.model.toLowerCase()
    const marca = row.brand.toLowerCase()
    const nombreCompleto = `${row.name || ''} ${row.lastname || ''}`.toLowerCase()
    const email = row.email.toLowerCase()
    const searchTermLower = data.table.search.toLowerCase()

    return (
      estado.includes(searchTermLower) ||
      sbu.includes(searchTermLower) ||
      modelo.includes(searchTermLower) ||
      marca.includes(searchTermLower) ||
      email.includes(searchTermLower) ||
      nombreCompleto.includes(searchTermLower)
    )
  }

  useEffect(() => {
    loadData()
    return () => {
      setData({
        mainList: [],
        listIndicadores: [],
        listByBrands: [],
        listByModels: [],
        listByRejected: [],
        listByMonth: [],
        showTopTen: true,
        loading: false,
        table: {
          search: '',
          page: 0,
          rowsPerPage: 10
        },
        currentTab: 0,
        page: 0,
        pageCoticed: 0,
        rowsPerPage: 20,
        rowsPerPageCoticed: 5,
        hover: {},
        anchorEl: null,
        openFind: false
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handleEscape = (event: any) => {
      if (event.key === 'Escape') {
        handleCloseFind()
      }
    }
    document.addEventListener('keydown', handleEscape)
    return () => {
      document.removeEventListener('keydown', handleEscape)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filteredRows = data.mainList.filter(row => matchesSearchTerm(row))
  const paginatedListModels = data.listByModels.slice(data.page * data.rowsPerPage, data.page * data.rowsPerPage + data.rowsPerPage)

  return (
    <div>
      <Grid container spacing={3} display='flex' alignItems='center' justifyContent='center'>
        <Grid item xs={12} sm={3}>
          <StatusCard loading={data.loading} color='#f5e7fa' indicador={data.listIndicadores.find(item => item.id_status === 1) !== undefined ? data.listIndicadores.find(item => item.id_status === 1).cantidad : 0} estado='Cotizado' textColor='#cf69f6' Icon={<RequestQuote style={{ fontSize: 60, color: '#cf69f6', opacity: 0.2 }} />} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <StatusCard loading={data.loading} color='#ffebee' indicador={data.listIndicadores.find(item => item.id_status === 2) !== undefined ? data.listIndicadores.find(item => item.id_status === 2).cantidad : 0} estado='Aprobado' textColor='#fe7070' Icon={<CheckCircle style={{ fontSize: 60, color: '#fe7070', opacity: 0.2 }} />} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <StatusCard loading={data.loading} color='#ffebee' indicador={data.listIndicadores.find(item => item.id_status === 6) !== undefined ? data.listIndicadores.find(item => item.id_status === 6).cantidad : 0} estado='No Aprobado' textColor='#fe7070' Icon={<Cancel style={{ fontSize: 60, color: '#fe7070', opacity: 0.2 }} />} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <StatusCard loading={data.loading} color='#fff3e0' indicador={data.listIndicadores.find(item => item.id_status === 4) !== undefined ? data.listIndicadores.find(item => item.id_status === 4).cantidad : 0} estado='Validado' textColor='#fc9432' Icon={<VerifiedUser style={{ fontSize: 60, color: '#fc9432', opacity: 0.2 }} />} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <StatusCard loading={data.loading} color='#f1fdef' indicador={data.listIndicadores.find(item => item.id_status === 3) !== undefined ? data.listIndicadores.find(item => item.id_status === 3).cantidad : 0} estado='Firmado' textColor='#54c45e' Icon={<AssignmentTurnedIn style={{ fontSize: 60, color: '#54c45e', opacity: 0.2 }} />} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <StatusCard loading={data.loading} color='#e9f3ff' indicador={data.listIndicadores.find(item => item.id_status === 5) !== undefined ? data.listIndicadores.find(item => item.id_status === 5).cantidad : 0} estado='Entregado' textColor='#6db1ff' Icon={<HourglassBottom style={{ fontSize: 60, color: '#6db1ff', opacity: 0.2 }} />} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <StatusCard loading={data.loading} color='#ebe7dd' indicador={data.listIndicadores.find(item => item.id_status === 7) !== undefined ? data.listIndicadores.find(item => item.id_status === 7).cantidad : 0} estado='Retirado Reuse' textColor='#82755b' Icon={<Tour style={{ fontSize: 60, color: '#82755b', opacity: 0.2 }} />} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <StatusCard loading={data.loading} color='#e1fff9' indicador={data.listIndicadores.find(item => item.id_status === 8) !== undefined ? data.listIndicadores.find(item => item.id_status === 8).cantidad : 0} estado='Rechazado' textColor='#00c2a8' Icon={<ThumbDown style={{ fontSize: 60, color: '#00c2a8', opacity: 0.2 }} />} />
        </Grid>
      </Grid>
      <div className='bg-white rounded px-md-5 py-4 mt-3'>
        <Tabs value={data.currentTab} onChange={(event, newValue) => setData({ ...data, currentTab: newValue })}>
          <Tab label='Principal' className='px-1 px-md-3' />
          {/* <Tab label='Dashboard' /> */}
          <Tab label='Equipos recibidos' className='px-1 px-md-3' />
          <Tab label='Equipos en custodia' className='px-1 px-md-3' disabled={![Number(process.env.REACT_APP_PERFIL_ADMIN), Number(process.env.REACT_APP_PERFIL_JEFETIENDA)].includes(userState.id_role)} hidden={![Number(process.env.REACT_APP_PERFIL_ADMIN), Number(process.env.REACT_APP_PERFIL_JEFETIENDA)].includes(userState.id_role)} />
          <Tab label='Equipos retirados' className='px-1 px-md-3' disabled={![Number(process.env.REACT_APP_PERFIL_ADMIN), Number(process.env.REACT_APP_PERFIL_JEFETIENDA)].includes(userState.id_role)} hidden={![Number(process.env.REACT_APP_PERFIL_ADMIN), Number(process.env.REACT_APP_PERFIL_JEFETIENDA)].includes(userState.id_role)} />
        </Tabs>
        {data.currentTab === 0 && (
          <div>
            <Paper elevation={0}>
              <CardHeader
                action={
                  <Stack direction='row' spacing={2}>
                    <ExportToExcelButton data={data.mainList} stage='listado_principal' />
                    <ButtonBase aria-label='Find' component='a' onClick={handleOpenFind}>
                      <LightTooltip title='Buscar'>
                        <Search style={{ width: '30px' }} />
                      </LightTooltip>
                    </ButtonBase>
                    <Popper open={data.openFind} anchorEl={data.anchorEl} placement='bottom-start' disablePortal={false}>
                      <ClickAwayListener onClickAway={handleCloseFind}>
                        <Box ref={searchBoxRef} sx={{ p: 2, bgcolor: 'background.paper', boxShadow: 1, borderRadius: 1 }}>
                          <TextField
                            className='search-box'
                            label='Buscar'
                            variant='outlined'
                            value={data.table.search}
                            onChange={(e) => setData({ ...data, table: { ...data.table, search: e.target.value } })}
                            fullWidth
                          />
                          <Tooltip
                            title={<span style={{ fontSize: '0.75rem' }}>Aquí puedes buscar/filtrar por cualquier elemento de la tabla. Por ejemplo: "Rechazado".</span>}
                            placement='top'
                          >
                            <IconButton
                              className='export-button'
                              color='primary'
                              style={{
                                position: 'absolute',
                                top: -5,
                                right: -5,
                                borderRadius: '50%',
                                minWidth: '20px',
                                height: '20px',
                                padding: 0
                              }}
                            >
                              <Info />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </ClickAwayListener>
                    </Popper>
                  </Stack>
        }
              />
              <Divider />
              {data.loading
                ? <CircularProgress />
                : (
                  <TableContainer>
                    <Table aria-label='a dense table'>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>#</StyledTableCell>
                          <StyledTableCell align='left'>NOMBRE</StyledTableCell>
                          <StyledTableCell align='center'>EMAIL</StyledTableCell>
                          <StyledTableCell align='center'>DISPOSITIVO</StyledTableCell>
                          <StyledTableCell align='center'>MARCA</StyledTableCell>
                          <StyledTableCell align='center'>MODELO</StyledTableCell>
                          <StyledTableCell align='center'>MONTO DESCUENTO</StyledTableCell>
                          <StyledTableCell align='center'>CÓDIGO</StyledTableCell>
                          <StyledTableCell align='center' style={{ width: '150px' }}>ESTADO</StyledTableCell>
                          <StyledTableCell align='center' />
                          <StyledTableCell align='center' style={{ width: '118px' }} />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(filteredRows
                          .slice(data.table.page * data.table.rowsPerPage, data.table.page * data.table.rowsPerPage + data.table.rowsPerPage)
                        ).map((row: any) => {
                          const color = StatusColor(row.status)
                          return (
                            <StyledTableRow
                              key={row.id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <StyledTableCell component='th' scope='row'>
                                {row.id}
                              </StyledTableCell>
                              <StyledTableCell align='left'>
                                {row.name ? (row.name.trim() + ' ' + (row.lastname || '')).toUpperCase() : 'Sin dato'}
                              </StyledTableCell>
                              <StyledTableCell align='center'>{row.email}</StyledTableCell>
                              <StyledTableCell align='center'>{row.sbu}</StyledTableCell>
                              <StyledTableCell align='center'>{row.brand}</StyledTableCell>
                              <StyledTableCell align='center'>{row.model}</StyledTableCell>
                              <StyledTableCell align='center'>{CurrencyFormat(row.discount_amount)}</StyledTableCell>
                              <StyledTableCell align='center'>{row.login_code !== null ? row.login_code : 'Sin código'}</StyledTableCell>
                              <StyledTableCell align='center'>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  {row.status === 'RECHAZADO'
                                    ? (
                                      <LightTooltip title={row.cancel_reason}>
                                        <Badge
                                          badgeContent={row.status}
                                          sx={{
                                            '& .MuiBadge-badge': {
                                              backgroundColor: color,
                                              color: '#fff',
                                              textAlign: 'center',
                                              fontSize: '11px'
                                            }
                                          }}
                                        />
                                      </LightTooltip>
                                      )
                                    : (
                                      <Badge
                                        badgeContent={row.status}
                                        sx={{
                                          '& .MuiBadge-badge': {
                                            backgroundColor: color,
                                            color: '#fff',
                                            textAlign: 'center',
                                            fontSize: '11px'
                                          }
                                        }}
                                      />
                                      )}

                                </div>
                              </StyledTableCell>
                              <StyledTableCell align='center'>
                                {
                              row.recalculate === '1' && row.status !== 'RECHAZADO' && row.wasRecalculated !== 1
                                ? (
                                  <Tooltip
                                    title={<span style={{ fontSize: '0.75rem' }}>El modelo ingresado en el Trade-In no coincide con el modelo revisado. Se debe recalcular en caso que cuente con factibilidad.</span>}
                                    placement='top'
                                  >
                                    <IconButton
                                      className='export-button'
                                      color='warning'
                                      style={{
                                        borderRadius: '50%',
                                        minWidth: '20px',
                                        height: '20px',
                                        padding: 0
                                      }}
                                    >
                                      <Info />
                                    </IconButton>
                                  </Tooltip>
                                  )
                                : null
                            }
                              </StyledTableCell>
                              <StyledTableCell align='center'>
                                <LoadingButton
                                  type='submit'
                                  fullWidth
                                  variant='contained'
                                  loading={data.loading}
                                  onClick={() => {
                                    triggerDetails(row)
                                  }}
                                  size='small'
                                  style={{ fontSize: '10px', backgroundColor: '#333', position: 'relative', opacity: data.hover[row.id] ? 0.9 : 1 }}
                                  onMouseEnter={() => handleMouseEnter(row.id)}
                                  onMouseLeave={() => handleMouseLeave(row.id)}
                                  endIcon={data.hover[row.id] && <ArrowForwardIos style={{ fontSize: '12px' }} />}
                                >
                                  <span style={{ textAlign: 'left', flexGrow: 1 }}>Detalles</span>
                                </LoadingButton>
                              </StyledTableCell>
                            </StyledTableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                    <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', py: 1.5, borderTop: 1, borderTopColor: '#D7D7D7' }}>
                      <span style={{ fontSize: 14 }}>Filas por página:</span>
                      <Select
                        size='small'
                        value={data.table.rowsPerPage}
                        onChange={(event) => setData({ ...data, table: { page: 0, search: '', rowsPerPage: Number(event.target.value) } })}
                        sx={{
                          marginLeft: '8px',
                          marginRight: '16px',
                          '& .MuiInputBase-input': {
                            py: 0.5
                          }
                        }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                      </Select>
                      <Pagination
                        count={Math.ceil(data.mainList.length / data.table.rowsPerPage)}
                        color='primary'
                        page={data.table.page + 1}
                        onChange={(event, value) => {
                          setData({ ...data, table: { search: '', page: value - 1, rowsPerPage: 10 } })
                        }}
                      />
                    </Box>
                  </TableContainer>
                  )}
            </Paper>
          </div>
        )}
        {data.currentTab === 4 && (
          <div>
            <Grid container direction='row' spacing={2} my={1} alignItems='stretch'>
              <Grid item md={12}>
                <Card style={{ flex: 1 }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, pb: 0 }}>
                      <Typography variant='h6' color='textSecondary'>
                        Cotizaciones por mes
                      </Typography>
                    </Box>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={optionsCoticedByMonth}
                      width='100%'
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6} style={{ display: 'flex', flexDirection: 'column' }}>
                <Card style={{ flex: 1 }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, pb: 0 }}>
                      <Typography variant='h6' color='textSecondary'>
                        Gráfico modelos más cotizados
                      </Typography>
                      <Button
                        variant='contained' size='small' onClick={toggleData} sx={{ fontSize: '0.8rem', minWidth: 'fit-content', py: 0, px: 1 }}
                      >
                        {data.showTopTen ? 'Mostrar todos' : 'Mostrar top 5'}
                      </Button>
                    </Box>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={optionsModels}
                      width='100%'
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6} style={{ display: 'flex', flexDirection: 'column' }}>
                <Card style={{ flex: 1 }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, pb: 0 }}>
                      <Typography variant='h6' color='textSecondary'>
                        Tabla modelos más cotizados
                      </Typography>
                      <ExportToExcelButton data={data.listByModels} stage='modelos_cotizados' />
                    </Box>
                    <Box sx={{ p: 1, pb: 0 }}>
                      <TableContainer component={Paper}>
                        <Table aria-label='customized table'>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align='left'>#</StyledTableCell>
                              <StyledTableCell align='left'>Modelo</StyledTableCell>
                              <StyledTableCell align='right'>N° Veces cotizado</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {paginatedListModels &&
                              paginatedListModels.map((row, index) => {
                                return (
                                  <StyledTableRow key={index}>
                                    <StyledTableCell align='left'>{index + 1 + data.pageCoticed * data.rowsPerPageCoticed}</StyledTableCell>
                                    <StyledTableCell align='left'>({row.model}</StyledTableCell>
                                    <StyledTableCell align='right'>{row.count}</StyledTableCell>
                                  </StyledTableRow>
                                )
                              })}
                          </TableBody>
                        </Table>
                        <TablePagination
                          rowsPerPageOptions={[20, 50, 80, 100]}
                          component='div'
                          count={data.listByModels.length}
                          rowsPerPage={data.rowsPerPageCoticed}
                          page={data.pageCoticed}
                          onPageChange={handleChangePageCoticed}
                          onRowsPerPageChange={handleChangeRowsPerPageCoticed}
                          labelRowsPerPage='Filas por página'
                        />
                      </TableContainer>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6} style={{ display: 'flex', flexDirection: 'column' }}>
                <Card style={{ flex: 1 }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, pb: 0 }}>
                      <Typography variant='h6' color='textSecondary'>
                        Gráfico marcas más cotizadas
                      </Typography>
                    </Box>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={optionsBrands}
                      width='100%'
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6} style={{ display: 'flex', flexDirection: 'column' }}>
                <Card style={{ flex: 1 }}>
                  <CardContent>
                    <Typography variant='h6' color='textSecondary'>
                      Tabla marcas más cotizadas
                    </Typography>
                    <Box sx={{ p: 1, pb: 0 }}>
                      <TableContainer component={Paper}>
                        <Table aria-label='customized table'>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align='left'>#</StyledTableCell>
                              <StyledTableCell align='left'>Marca</StyledTableCell>
                              <StyledTableCell align='right'>N° Veces cotizado</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.listByBrands &&
                              data.listByBrands.map((row, index) => {
                                return (
                                  <StyledTableRow key={index}>
                                    <StyledTableCell align='left'>{index + 1}</StyledTableCell>
                                    <StyledTableCell align='left'>{row.brand}</StyledTableCell>
                                    <StyledTableCell align='right'>{row.count}</StyledTableCell>
                                  </StyledTableRow>
                                )
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
                <Card style={{ flex: 1 }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, pb: 0 }}>
                      <Typography variant='h6' color='textSecondary'>
                        Gráfico causas de Rechazo
                      </Typography>
                    </Box>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={optionsRejected}
                      width='100%'
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
                <Card style={{ flex: 1 }}>
                  <CardContent>
                    <Typography variant='h6' color='textSecondary'>
                      Tabla causas de Rechazo
                    </Typography>
                    <Box sx={{ p: 1, pb: 0 }}>
                      <TableContainer component={Paper}>
                        <Table aria-label='customized table'>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align='left'>#</StyledTableCell>
                              <StyledTableCell align='left'>Motivo rechazo</StyledTableCell>
                              <StyledTableCell align='left'>Descuento trade-in</StyledTableCell>
                              <StyledTableCell align='left'>Descuento deseado</StyledTableCell>
                              <StyledTableCell align='right'>N° de veces</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.listByRejected &&
                              data.listByRejected.map((row, index) => {
                                return (
                                  <StyledTableRow key={index}>
                                    <StyledTableCell align='left'>{index + 1}</StyledTableCell>
                                    <StyledTableCell align='right'>{row.other_reason !== '' ? row.other_reason : row.cancel_reason}</StyledTableCell>
                                    <StyledTableCell align='right'>{CurrencyFormat(row.discount_amount)}</StyledTableCell>
                                    <StyledTableCell align='right'>{CurrencyFormat(row.desired_price)}</StyledTableCell>
                                    <StyledTableCell align='right'>{row.count}</StyledTableCell>
                                  </StyledTableRow>
                                )
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        )}
        {data.currentTab === 1 && (
          <TableRecibidos />
        )}
        {data.currentTab === 2 && (
          <TableCustody />
        )}
        {data.currentTab === 3 && (
          <TableRetirados />
        )}
      </div>
    </div>

  )
}

export default TableList
