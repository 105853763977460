import { Send as SendIcon } from '@mui/icons-material'
import { Box, Button, FormControlLabel, Modal, Radio, RadioGroup, Typography } from '@mui/material'
import { useState } from 'react'
import { webApiService } from '../../../services'
import { useNavigate, useParams } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
import { useDetailsContext } from '../context/Details.context'
import { LoadingButton } from '@mui/lab'
import { IAppStore } from '../../../models/store.model'
import { useSelector } from 'react-redux'

const ResendCode = () => {
  const [data, setData] = useState<{ open: boolean, respuesta: string, done: boolean; login_code: string; new_id_tradein: string; loading: boolean}>({ open: false, respuesta: '', done: false, login_code: '', new_id_tradein: '', loading: false })
  const { id } = useParams()
  const { ctx, saveCtx } = useDetailsContext()
  const userState = useSelector((store: IAppStore) => store.user)
  const navigate = useNavigate()

  const handleClose = () => {
    setData({ open: false, respuesta: '', done: false, login_code: '', new_id_tradein: '', loading: false })
  }

  const handleResendCode = () => {
    setData({ ...data, loading: true })
    const objPhonecheck = {
      id_tradein: id,
      email: data.respuesta,
      name: ctx.tradeinDetails.name,
      lastname: ctx.tradeinDetails.lastname,
      id_user: userState.id
    }
    webApiService.resendCode(objPhonecheck)
      .then((response) => {
        enqueueSnackbar('Se ha enviado el nuevo código al email:' + data.respuesta, { variant: 'success' })
        console.log('data handleResendCode ==>', response.data)
        setData({ ...data, loading: false, login_code: response.data.login_code, done: true, new_id_tradein: response.data.new_id_tradein })
        saveCtx({ ...ctx, reload: true })
      }).catch((error) => {
        enqueueSnackbar('Hubo un error al reenviar el código', { variant: 'error' })
        saveCtx({ ...ctx, reload: false })
        setData({ ...data, loading: false })
        console.log('error ==>', error)
      })
  }

  const handleContinue = () => {
    console.log('data.new_id_tradein ==>', String(data.new_id_tradein))
    navigate('/details/' + String(data.new_id_tradein))
  }

  return (
    <div>
      <Button
        variant='contained'
        color='warning'
        size='small'
        style={{ marginLeft: '5px' }}
        endIcon={<SendIcon style={{ fontSize: '12px' }} />}
        onClick={() => { setData({ ...data, open: true }) }}
      >
        Reenviar código
      </Button>
      <Modal open={data.open} onClose={handleClose}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            p: 2
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: '800px',
              backgroundColor: 'white',
              borderRadius: 2,
              textAlign: 'center',
              maxHeight: '90vh',
              overflowY: 'auto'
            }}
          >
            <Typography
              id='modal-title'
              variant='h6'
              component='h2'
              sx={{
                backgroundColor: '#333',
                color: '#fff',
                width: '100%',
                padding: 2
              }}
            >
              Reenviar código para autoevaluación
            </Typography>
            <Box sx={{ mt: 2, mb: 2, p: 2 }}>
              {
                    data.done
                      ? (
                        <Box style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                          <Typography variant='h6' component='div' gutterBottom>
                            Hemos generado el codigo: <strong>{data.login_code}</strong>
                          </Typography>
                          <Typography variant='h6' component='div' gutterBottom>
                            Pincha en <strong>"Continuar</strong> para ir al nuevo Tradein.
                          </Typography>
                        </Box>
                        )
                      : (
                        <Box style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                          <Typography variant='h6' component='div' gutterBottom>
                            Enviaremos un nuevo código para volver a realizar la evaluación del dispositivo.
                          </Typography>
                          <RadioGroup
                            name='email_option'
                            value={data.respuesta}
                            onChange={(e) => setData({ ...data, respuesta: e.target.value })}
                            row={false}
                          >
                            <FormControlLabel value={ctx.tradeinDetails.email} control={<Radio />} label={`Email cliente (${ctx.tradeinDetails.email})`} />
                            <FormControlLabel value={userState.email} control={<Radio />} label={`Módulo (${userState.email})`} />
                          </RadioGroup>
                        </Box>
                        )
                }

            </Box>
            <Box
              sx={{
                backgroundColor: '#f1f1f1',
                width: '100%',
                padding: 2,
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
                display: 'flex',
                justifyContent: 'center',
                gap: 2
              }}
            >
              <Button
                variant='contained'
                size='small'
                color='error'
                onClick={handleClose}
                disabled={data.done}
              >
                Cerrar
              </Button>
              {
                data.done
                  ? (
                    <LoadingButton variant='contained' color='primary' type='submit' disabled={data.respuesta === ''} onClick={handleContinue} loading={data.loading}>
                      Continuar
                    </LoadingButton>
                    )
                  : (
                    <LoadingButton variant='contained' color='primary' type='submit' disabled={data.respuesta === '' || data.done} onClick={handleResendCode} loading={data.loading}>
                      Enviar
                    </LoadingButton>
                    )
              }
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default ResendCode
