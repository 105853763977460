import React, { useState, useRef } from 'react'
import { TextField, Button, Box, Paper, Typography, IconButton } from '@mui/material'
import SignatureCanvas from 'react-signature-canvas'
import { enqueueSnackbar } from 'notistack'
import { webApiService } from '../../services'
import { LoadingButton } from '@mui/lab'
import { useSelector } from 'react-redux'
import { IAppStore } from '../../models/store.model'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { pdf } from '@react-pdf/renderer'
import ManifiestoPickupPDF from './components/ManifiestoPickupPDF'
import { blobToBase64 } from '../../utils/blobToBase64.util'

interface IOwnProps {
  name: string;
  lastname: string;
  seals: { seal: string; seal2: string }[]; // Cambio en el tipo de dato para manejar sellos dobles
  signature_url: string;
  validation: boolean;
  signatureError: boolean;
  isSaveDisabled: boolean;
  loading: boolean;
  resValiditySeal: any;
}

const PickupForm: React.FC = () => {
  const [data, setData] = useState<IOwnProps>({
    name: '',
    lastname: '',
    seals: [{ seal: '', seal2: '' }], // Inicializamos con dos sellos
    signature_url: '',
    validation: false,
    signatureError: false,
    isSaveDisabled: false,
    loading: false,
    resValiditySeal: []
  })
  console.log('data => ', data)

  const sigCanvas = useRef<SignatureCanvas | null>(null)
  const userState = useSelector((store: IAppStore) => store.user)

  const handleChange = (e: any, i: number, field: 'seal' | 'seal2') => {
    const newSeals = [...data.seals]
    newSeals[i][field] = e.target.value
    setData({ ...data, seals: newSeals })
  }

  const handleClear = () => {
    sigCanvas.current?.clear()
    setData({ ...data, signatureError: false, signature_url: '' })
  }

  const handleSave = async () => {
    setData({ ...data, validation: true, loading: true })
    const signature = sigCanvas.current?.getTrimmedCanvas().toDataURL('image/png')
    console.log('signature ==>', signature)

    const sealsList = data.seals.map(seal => seal.seal)
    console.log('sealsList => ', sealsList)

    const list = await webApiService.getAllBySeal({ seals: sealsList }).then(res => res.data)
    console.log('🚀 ~ handleSave ~ list:', list)

    const pdfBlob = await pdf(<ManifiestoPickupPDF firma={String(sigCanvas.current?.toDataURL('image/png'))} detalle={{ name: data.name, lastName: data.lastname }} listado={list} />).toBlob()
    console.log('🚀 ~ handleAcceptConfirmation ~ pdfBlob:', pdfBlob)
    const base64PDF = await blobToBase64(pdfBlob)
    console.log('🚀 ~ handleAcceptConfirmation ~ base64PDF:', base64PDF)

    const obj = {
      seals: data.seals,
      id_user: userState.id,
      picker_firstname: data.name,
      picker_lastname: data.lastname,
      picker_url_signature: signature,
      pdf: base64PDF
    }

    console.log('obj => ', obj)

    try {
      const res = await webApiService.validitySeal(obj)
      console.log('res => ', res)

      if (res.success) {
        enqueueSnackbar(res.message, { variant: 'success' })
        sigCanvas.current?.clear()
        setData({
          ...data,
          name: '',
          lastname: '',
          seals: [{ seal: '', seal2: '' }],
          signature_url: '',
          validation: false,
          signatureError: false,
          isSaveDisabled: false,
          loading: false,
          resValiditySeal: []
        })
      } else {
        enqueueSnackbar(res.message, { variant: 'error' })
        setData({ ...data, resValiditySeal: res.data, loading: false })
      }
    } catch (error: any) {
      console.log('error => ', error)
      enqueueSnackbar(error.message, { variant: 'error' })
      setData({ ...data, loading: false })
    }
  }

  // useEffect(() => {
  //   const isFormComplete =
  //     data.name.trim() !== '' &&
  //     data.lastname.trim() !== '' &&
  //     data.seals.every(seal => seal.trim() !== '') &&
  //     sigCanvas.current &&
  //     !sigCanvas.current.isEmpty()

  //   setData({ ...data, isSaveDisabled: !isFormComplete })
  // }, [data])

  const handleSignatureEnd = () => {
    if (sigCanvas.current && !sigCanvas.current.isEmpty()) {
      const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
      setData((prevData) => ({ ...prevData, signature_url: signature }))
    }
  }

  const addSeal = () => {
    setData({ ...data, seals: [...data.seals, { seal: '', seal2: '' }] })
  }

  const handleRemoveSeal = (index: number) => {
    const newSeals = data.seals.filter((_, i) => i !== index)
    setData({ ...data, seals: newSeals })
  }

  return (
    <Paper sx={{ padding: 3, maxWidth: 500, margin: 'auto', marginTop: 5 }}>
      <Typography variant='h6' gutterBottom>
        Formulario de Recogida
      </Typography>
      <Box component='form' noValidate autoComplete='off'>
        <TextField
          label='Nombre'
          variant='outlined'
          fullWidth
          margin='normal'
          name='name'
          value={data.name}
          onChange={(e) => setData({ ...data, name: e.target.value })}
          error={data.validation && data.name.trim() === ''}
          helperText={data.validation && data.name.trim() === '' ? 'Debe ingresar su nombre' : ''}
          onBlur={() => setData({ ...data, validation: true })}
        />
        <TextField
          label='Apellido'
          variant='outlined'
          fullWidth
          margin='normal'
          name='lastname'
          value={data.lastname}
          onChange={(e) => setData({ ...data, lastname: e.target.value })}
          error={data.validation && data.lastname.trim() === ''}
          helperText={data.validation && data.lastname.trim() === '' ? 'Debe ingresar su apellido' : ''}
          onBlur={() => setData({ ...data, validation: true })}
        />
        {data.seals.map((seal, i) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }} key={i}>
            <TextField
              sx={{ marginRight: 2 }}
              label='Sello 1'
              variant='outlined'
              fullWidth
              margin='normal'
              name='seal'
              error={data.resValiditySeal.includes(seal.seal)}
              value={seal.seal}
              onChange={(e) => handleChange(e, i, 'seal')}
            />
            <TextField
              label='Sello 2'
              variant='outlined'
              fullWidth
              margin='normal'
              name='seal2'
              error={data.resValiditySeal.includes(seal.seal2)}
              value={seal.seal2}
              onChange={(e) => handleChange(e, i, 'seal2')}
            />
            {i === data.seals.length - 1
              ? (
                <IconButton onClick={addSeal} disabled={seal.seal === '' && seal.seal2 === ''}>
                  <AddCircleIcon color={seal.seal === '' && seal.seal2 === '' ? 'disabled' : 'primary'} />
                </IconButton>
                )
              : (
                <IconButton onClick={() => handleRemoveSeal(i)}>
                  <RemoveCircleIcon color='secondary' />
                </IconButton>
                )}

            {i !== 0 && i === data.seals.length - 1 &&
              <IconButton onClick={() => handleRemoveSeal(i)}>
                <RemoveCircleIcon color='secondary' />
              </IconButton>}
          </Box>
        ))}
        <Typography variant='subtitle1' gutterBottom>
          Firma
        </Typography>
        <Box
          sx={{
            border: '1px solid #ccc',
            padding: 2,
            marginBottom: 2,
            borderRadius: 2
          }}
        >
          <SignatureCanvas
            ref={sigCanvas}
            canvasProps={{ width: 450, height: 150, className: 'sigCanvas' }}
            penColor='black'
            onEnd={handleSignatureEnd}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <LoadingButton
            variant='contained'
            color='primary'
            onClick={handleSave}
            disabled={!data.name || !data.lastname || !data.seals.every(seal => seal.seal.trim() !== '' && seal.seal2.trim() !== '') || !data.signature_url}
            loading={data.loading}
          >
            Guardar
          </LoadingButton>
          <Button variant='outlined' color='secondary' onClick={handleClear}>
            Borrar Firma
          </Button>
        </Box>
      </Box>
    </Paper>
  )
}

export default PickupForm
