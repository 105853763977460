import { createSlice } from '@reduxjs/toolkit'
import { UserItem } from '../../models/user.model'
import { clearLocalStorage, persistLocalStorage } from '../../utils/localStorage.util'

// import axios from 'axios'

export const EmptyUserState: UserItem = {
  id: 0,
  email: '',
  name: '',
  id_company_area: 0,
  id_role: 0,
  name_role: '',
  active: 0,
  created_at: new Date(),
  updated_at: new Date(),
  permisos: [],
  token: '',
  logged: false
}

export const UserKey = 'user'

const userSlice = createSlice({
  name: 'auth',
  initialState: localStorage.getItem(UserKey) !== null ? JSON.parse(String(localStorage.getItem(UserKey))) : EmptyUserState,
  reducers: {
    createUser: (_state, action) => {
      persistLocalStorage<any>(UserKey, action.payload)
      return action.payload
    },
    updateUser: (state, action) => {
      const result = { ...state, ...action.payload }
      persistLocalStorage<any>(UserKey, result)
      return result
    },
    resetUser: () => {
      clearLocalStorage(UserKey)
      return EmptyUserState
    }
  }
})

export const { createUser, updateUser, resetUser } = userSlice.actions

export default userSlice.reducer
