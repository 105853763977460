import React, { createContext, useContext, useState } from 'react'

interface IOwnProps {
    tradeinDetails: any;
    statusHistory: any[];
    questions: any[];
    answers: Array<{ id_question: number; question?: string; id_answer?: number; text_answer?: string; score: number }>;
    entrys: any[];
    certificateTheft: string;
    certificatePossession: string;
    actionType: string;
    certificateType: string;
    openModal: boolean;
    batteryLife: string;
    emailRecipient: string;
    rut: string;
    address: string;
    state: string;
    city: string;
    serie: string;
    files: any[];
    reload: boolean;
    displayContract: number
}

export type DetailsContextType = {
  ctx: IOwnProps;
  saveCtx: (ctx: IOwnProps) => void;
};

const DetailsContext = createContext<DetailsContextType | null>(null)

export const DetailsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [ctx, setCtx] = useState<IOwnProps>({
    tradeinDetails: [],
    statusHistory: [],
    questions: [],
    answers: [],
    entrys: [],
    certificateTheft: '',
    certificatePossession: '',
    actionType: '',
    certificateType: '',
    openModal: false,
    batteryLife: '',
    emailRecipient: '',
    rut: '',
    address: '',
    state: '',
    city: '',
    serie: '',
    files: [],
    reload: false,
    displayContract: 0
  })

  const saveCtx = (_ctx: IOwnProps) => {
    setCtx(_ctx)
  }

  return <DetailsContext.Provider value={{ ctx, saveCtx }}>{children}</DetailsContext.Provider>
}

export const useDetailsContext = (): DetailsContextType => {
  const context = useContext(DetailsContext)
  if (!context) {
    throw new Error('useDetailsContext must be used within a DetailsProvider')
  }
  return context
}
