import axios from 'axios'

const axiosInstance = axios.create({
  headers: { 'Content-Type': 'application/json; charset=utf-8', Accept: 'text/json' }
})

export const sourceAxios = axios.CancelToken.source()

export const webApiService = {
  getUserProfile: async (_email: string, _poolId: string) => {
    const url = `${process.env.REACT_APP_SERVICE_USER_PROFILE}getByEmailAndPoolId/${_email}/${_poolId}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getList: async () => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}getAll`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllCoticedBrands: async () => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}getAllCoticedBrands`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllCoticedModels: async () => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}getAllCoticedModels`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getRejected: async () => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}getRejected`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getCoticedByMonth: async () => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}getCoticedByMonth`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  entregar: async (data: any) => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}update`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  getHistoryById: async (data: string) => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}getHistoryById`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  getAllById: async (_tradeinId: string) => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}getAllById/${_tradeinId}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  update: async (data: any) => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}update`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  getChecklistQuestions: async () => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}getChecklistQuestions`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getEntrysById: async (_tradeinId: string) => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}getAnswersById/${_tradeinId}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  saveFiles: async (data: any) => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}saveFiles`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  getFiles: async (_tradeinId: string) => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}getFiles/${_tradeinId}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  rechazarTradein: async (data: any) => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}rechazarTradein`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  resendCode: async (data: any) => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}resendCode`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  getAllCR: async () => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}getAllCR`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getQuestions: async () => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}getQuestions`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  recalculate: async (data: any) => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}recalculate`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  getAllEntregados: async (emailUser: string, idRole: number, poolId: string) => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}getAllEntregados/${emailUser}/${idRole}/${poolId}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  validitySeal: async (data: any) => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}validitySeal`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  sendToCustody: async (data: any) => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}sendToCustody`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  getAllCustody: async (idUser: number) => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}getAllCustody/${idUser}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getAllBySeal: async (data: any) => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}getAllBySeal`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  getAllRetiradoReuse: async () => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}getAllRetiradoReuse`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  updateOnlyFolio: async (data: any) => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}updateOnlyFolio`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  getListTradeIn: async (data: any) => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}getList`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  getCountStatus: async () => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}getCountStatus`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getBrandBySbu: async (idSbu: string) => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}getBrandBySbu/${idSbu}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getModelByBrand: async (idSbu: string, idBrand: string) => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}getModelByBrand/${idSbu}/${idBrand}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getCapacityByModel: async (idSbu: string, idBrand: string, idModel: string) => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}getCapacityByModel/${idSbu}/${idBrand}/${idModel}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  updatePCheck: async (data: any) => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}updatePCheck`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  checkSeal: async (data: any) => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}checkSeal`
    return await axiosInstance.request({ method: 'POST', url, data }).then((res: any) => res.data)
  },
  getAllAnswers: async (idTradein: string) => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}getAllAnswers/${idTradein}`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  },
  getTradeinConfig: async () => {
    const url = `${process.env.REACT_APP_CRUD_TRADEIN_AUFBAU}getTradeinConfig`
    return await axiosInstance.request({ method: 'GET', url }).then((res: any) => res.data)
  }
}
