import React from 'react'
import TableList from './components/TableList'

const HomePage: React.FC = () => {
  return (
    <div>
      <TableList />
    </div>
  )
}

export default HomePage
