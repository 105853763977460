import React, { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import LoginPage from './pages/LoginPage/LoginPage'
import HomePage from './pages/HomePage/HomePage'
import Details from './pages/Details/Details'
import AuthRoleRouterGuard from './guards/AuthRoleRouterGuard'
import Redirect from './components/Redirect/Redirect'
import Layout from './components/Layout/Layout'
import { PrivateRoutes, PublicRoutes } from './constants/routes'
import './theme/App.scss'
import { DetailsProvider } from './pages/Details/context/Details.context'
import PickupForm from './pages/PickupForm/PickupForm'
import { IAppStore } from './models/store.model'
import { useSelector } from 'react-redux'

const App: FC = () => {
  const userState = useSelector((store: IAppStore) => store.user)
  console.log('🚀 ~ userState:', (userState.id_role !== Number(process.env.REACT_APP_PERFIL_COURIER)))

  return (
    <Routes>
      <Route path={PublicRoutes.LOGIN} element={<LoginPage />} />
      <Route path={PublicRoutes.ROOT} element={(userState.id_role !== Number(process.env.REACT_APP_PERFIL_COURIER)) ? <Navigate to={PrivateRoutes.HOME} /> : <Navigate to={PrivateRoutes.PICKUP_FORM} />} />
      <Route element={<Layout />}>
        <Route element={<AuthRoleRouterGuard roles={[Number(process.env.REACT_APP_PERFIL_ADMIN), Number(process.env.REACT_APP_PERFIL_EJECUTIVO), Number(process.env.REACT_APP_PERFIL_JEFETIENDA), Number(process.env.REACT_APP_PERFIL_COURIER)]} />}>
          <Route element={<AuthRoleRouterGuard roles={[Number(process.env.REACT_APP_PERFIL_ADMIN), Number(process.env.REACT_APP_PERFIL_EJECUTIVO), Number(process.env.REACT_APP_PERFIL_JEFETIENDA)]} />}>
            <Route path={PrivateRoutes.HOME} element={<HomePage />} />
            <Route path='/details/:id' element={<DetailsProvider><Details /></DetailsProvider>} />
          </Route>
          <Route path={PrivateRoutes.PICKUP_FORM} element={<PickupForm />} />
        </Route>
      </Route>
      <Route path='*' element={<Redirect url={PublicRoutes.ROOT} />} />
    </Routes>
  )
}

export default App
