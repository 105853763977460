import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { UserItem } from '../models/user.model'
import { PublicRoutes } from '../constants/routes'
import { FC } from 'react'

interface IOwnProps{
  roles: Array<number>
}

export const AuthRoleRouterGuard: FC<IOwnProps> = ({ roles }) => {
  const userState = useSelector((store: { user: UserItem }) => store.user)

  const PrivateValidationFragment = <Outlet />

  return userState.id > 0 && roles.includes(userState.id_role) ? PrivateValidationFragment : <Navigate replace to={PublicRoutes.LOGIN} />
}

export default AuthRoleRouterGuard
