import { LoadingButton } from '@mui/lab'
import { Alert, Box, Button, Modal, Paper, TextField, Typography } from '@mui/material'
import { pdf } from '@react-pdf/renderer'
import { useSnackbar } from 'notistack'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import SignatureCanvas from 'react-signature-canvas'
import { IAppStore } from '../../../../../models/store.model'
import { webApiService } from '../../../../../services'
import { blobToBase64 } from '../../../../../utils/blobToBase64.util'
import ManifiestoPDF from '../../ManifiestoPDF'

const MyCustomToolbarSelect: React.FC<{ list: any[], seleccionados: any[], reloadAction: () => void, visible: boolean }> = ({ list, seleccionados, reloadAction, visible }) => {
  const [data, setData] = useState<{ loading: boolean, open: boolean, form: { name: string, lastName: string, seal: string, seal2: string, signature: string, seal_weight: string } }>({ loading: false, open: false, form: { name: '', lastName: '', seal: '', seal2: '', signature: '', seal_weight: '' } })
  const signatureCanvasRef = useRef<any>(null)
  const { enqueueSnackbar } = useSnackbar()
  const userState = useSelector((store: IAppStore) => store.user)
  console.log('data => ', data)

  const handleClose = () => {
    setData({ ...data, open: false, loading: false })
  }

  const handleCustody = async () => {
    if (signatureCanvasRef.current.isEmpty()) {
      enqueueSnackbar('El receptor debe firmar', { variant: 'error' })
      return
    }

    setData({ ...data, loading: true })
    console.log('form ===>', data.form)

    try {
      const res = await webApiService.checkSeal({ seal: data.form.seal, seal2: data.form.seal2 })
      if (res.data.length > 0) {
        enqueueSnackbar('Uno de los sellos ya existe, utilice otro por favor.', { variant: 'error' })
        setData({ ...data, loading: false })
        return
      }

      const pdfBlob = await pdf(<ManifiestoPDF firma={signatureCanvasRef.current.toDataURL('image/png')} detalle={data.form} listado={list.filter((e, i) => seleccionados.includes(i))} />).toBlob()
      console.log('🚀 ~ handleAcceptConfirmation ~ pdfBlob:', pdfBlob)
      const base64PDF = await blobToBase64(pdfBlob)
      console.log('🚀 ~ handleAcceptConfirmation ~ base64PDF:', base64PDF)

      await webApiService.sendToCustody({ ...data.form, signature: signatureCanvasRef.current.toDataURL('image/png'), modificados: list.filter((e, i) => seleccionados.includes(i)), id_user: userState.id, pdf: base64PDF })
      enqueueSnackbar('Folios guardados correctamente.', { variant: 'success' })
      setData({ ...data, open: false, loading: false, form: { name: '', lastName: '', seal: '', seal2: '', signature: '', seal_weight: '' } })
      reloadAction()
    } catch (err: any) {
      setData({ ...data, loading: false })
      enqueueSnackbar('Error: ' + err.message, { variant: 'error' })
    }
  }

  const clearSignature = async () => {
    if (signatureCanvasRef.current !== null) {
      signatureCanvasRef.current.clear()
    }
  }

  return (
    <div>
      {visible
        ? <Button component='label' variant='contained' color='primary' className='mx-1' disabled={seleccionados.length === 0 || !visible} onClick={() => { setData({ ...data, open: true }) }}>Entregar a custodia</Button>
        : <Alert severity='info'>Para entregar a custodia, ingrese folio a todos los registros</Alert>}
      <Modal open={data.open} onClose={handleClose}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            p: 2
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: '800px',
              backgroundColor: 'white',
              borderRadius: 2,
              textAlign: 'center',
              maxHeight: '90vh',
              overflowY: 'auto'
            }}
          >
            <Typography
              id='modal-title'
              variant='h6'
              component='h2'
              sx={{
                backgroundColor: '#333',
                color: '#fff',
                width: '100%',
                padding: 2
              }}
            >
              Entregar a custodia
            </Typography>
            <Paper variant='outlined' sx={{ padding: 2 }} className='small'>
              <div className='mb-2'>
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  value={data.form.name}
                  onChange={e => { setData({ ...data, form: { ...data.form, name: e.target.value } }) }}
                  id='name'
                  label='Nombre'
                  name='name'
                  autoFocus
                />
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  value={data.form.lastName}
                  onChange={e => { setData({ ...data, form: { ...data.form, lastName: e.target.value } }) }}
                  id='lastName'
                  label='Apellido'
                  name='lastName'
                />
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  value={data.form.seal}
                  onChange={e => { setData({ ...data, form: { ...data.form, seal: e.target.value } }) }}
                  id='first_seal'
                  label='1° Sello'
                  name='seal'
                />
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  value={data.form.seal2}
                  onChange={e => { setData({ ...data, form: { ...data.form, seal2: e.target.value } }) }}
                  id='second_seal'
                  label='2° Sello'
                  name='seal'
                />
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  value={data.form.seal_weight}
                  onChange={e => { setData({ ...data, form: { ...data.form, seal_weight: e.target.value } }) }}
                  id='seal_weight'
                  label='Peso'
                  name='seal_weight'
                />
                <p>Firmar:</p>
                <div style={{ border: 'solid 1px rgba(0, 0, 0, 0.23)', display: 'inline-block' }}>
                  <SignatureCanvas penColor='black' canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }} ref={signatureCanvasRef} />
                </div>
                <div>
                  <Button variant='contained' onClick={clearSignature}>
                    Limpiar firma
                  </Button>
                </div>
              </div>

            </Paper>
            <Box
              sx={{
                backgroundColor: '#f1f1f1',
                width: '100%',
                padding: 2,
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
                display: 'flex',
                justifyContent: 'center',
                gap: 2
              }}
            >
              <Button
                variant='contained'
                size='small'
                color='error'
                onClick={handleClose}
              >
                Cancelar
              </Button>
              <LoadingButton loading={data.loading} variant='contained' color='primary' type='submit' disabled={data.form.name === '' || data.form.lastName === '' || data.form.seal === '' || data.form.seal2 === '' || (data.form.seal_weight === '' && Number(process.env.REACT_APP_WEIGHT_REQUIRED) === 1)} onClick={handleCustody}>
                Entregar
              </LoadingButton>
            </Box>
          </Box>

        </Box>
      </Modal>
    </div>
  )
}
export default MyCustomToolbarSelect
