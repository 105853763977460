import { Alert, Button, Card, CardContent, Grid, ImageList, ImageListItem, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { useDetailsContext } from '../context/Details.context'
import { fromImage } from 'imtool'
import { blobToBase64 } from '../../../utils/blobToBase64.util'
import { webApiService } from '../../../services'
import { useParams } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'

const Multimedia: React.FC = () => {
  const { ctx, saveCtx } = useDetailsContext()
  const { id } = useParams()
  const [data, setData] = useState<{ loading: boolean, imagenes: any[] }>({ loading: false, imagenes: [] })

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const validFiles = acceptedFiles.filter(file =>
      ['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)
    )
    console.log('🚀 ~ onDrop ~ validFiles:', validFiles)

    // Asegúrate de que 'files' es de tipo 'File[]'
    saveCtx({
      ...ctx,
      files: [...ctx.files, ...validFiles]
    })
  }, [ctx, saveCtx])

  const accept: Accept = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg']
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept
  })

  const removeFile = (file: File, event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    saveCtx({
      ...ctx,
      files: ctx.files.filter(f => f !== file)
    })
  }

  const uploadFiles = async () => {
    for (const element of ctx.files) {
      if (element.nameFile === undefined) {
        console.log('entro', ctx)
        delete element.preview
        element.blob = await fromImage(element).then((tool: any) => tool.thumbnail(800, false).toBlob())
        element.base64 = await blobToBase64(element.blob)
        delete element.blob
      }
    }
    console.log('🚀 ~ forawait ~ ctx.files:', ctx.files)
    setData({ ...data, loading: true })
    webApiService.saveFiles({ id, files: ctx.files }).then(res => {
      enqueueSnackbar('Imagenes cargadas con exito', { variant: 'success' })
      loadData()
      saveCtx({ ...ctx, files: [], reload: true })
      setData({ ...data, loading: false })
    }).catch((error) => {
      enqueueSnackbar(error.message, { variant: 'error' })
      console.log('error ==>', error)
      setData({ ...data, loading: false })
    })
  }

  const loadData = () => {
    setData({ ...data, loading: true })
    webApiService.getFiles(String(id)).then((res: any) => {
      setData({ loading: false, imagenes: res.data.filter((item: any) => !item.name.includes('batteryHealth')) })
    }).catch((error) => {
      enqueueSnackbar(error.message, { variant: 'error' })
      console.log('error ==>', error)
      setData({ ...data, loading: false })
    })
  }

  useEffect(() => {
    loadData()
    // Cleanup URLs
    return () => {
      ctx.files.forEach(file => {
        URL.revokeObjectURL(URL.createObjectURL(file))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx.files])

  return (
    <Card>
      <CardContent>
        <div className='container'>
          <div className='row mb-2' style={{ borderBottom: '1px solid #f3f1f1' }}>
            <div className='col-9'>
              <Typography variant='h6' component='div' gutterBottom style={{ fontSize: '18px' }}>
                Imágenes del dispositivo
              </Typography>
            </div>
            <div className='col-3 text-end'>
              <LoadingButton
                loading={data.loading} hidden={ctx.files.length === 0} variant='contained' color='primary'onClick={() => {
                  if (ctx.files.length < 6) {
                    enqueueSnackbar('No cargó las 6 imagenes', { variant: 'error' })
                  } else {
                    uploadFiles()
                  }
                }}
              >
                Cargar imágenes
              </LoadingButton>
            </div>
          </div>
          <Typography variant='body1' component='div' gutterBottom mt={2} mb={2}>
            <Alert severity='warning'>Se deben cargar al menos 4 fotos que acrediten declaración de estado estéticos del dispositivo y carnet de identidad del cliente por ambos lados.</Alert>
          </Typography>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <ImageList cols={3} rowHeight={164}>
                {data.imagenes.map((item) => (
                  <ImageListItem key={item.id}>
                    <img
                      srcSet={`${process.env.REACT_APP_BUCKET}imagenes/${id}/${item.name} 2x`}
                      src={`${process.env.REACT_APP_BUCKET}imagenes/${id}/${item.name}`}
                      alt={item.name}
                      loading='lazy'
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </div>
            <div className={data.imagenes.length > 0 ? 'col-12 col-md-6' : 'col-12'}>
              {ctx.tradeinDetails.id_status === 3 || (ctx.tradeinDetails.id_status === 4 && ctx.displayContract === 0 && ctx.tradeinDetails.isBatteryHealthy !== null)
                ? (
                  <div
                    {...getRootProps()} style={{
                      border: '2px dashed #cccccc',
                      borderRadius: '5px',
                      padding: '20px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      backgroundColor: isDragActive ? '#cce5ff' : '#ffffff',
                      color: isDragActive ? '#ffffff' : '#000000'
                    }}
                  >
                    <input {...getInputProps()} />
                    {
                isDragActive
                  ? (
                    <Typography>Arrastra y suelta las imágenes aquí...</Typography>
                    )
                  : (
                    <Typography>Arrastra y suelta las imágenes aquí, o haz clic para seleccionar archivos</Typography>
                    )
              }
                    <Typography variant='body2' color='textSecondary'>
                      (Solo se permiten archivos .png, .jpg y .jpeg)
                    </Typography>
                    <Grid container spacing={2} mt={2}>
                      {ctx.files.map((file, index) => (
                        <Grid item key={index} xs={12} sm={6} md={4}>
                          <Card>
                            <CardContent>
                              <img src={URL.createObjectURL(file)} alt={`preview ${index}`} style={{ width: '100%' }} />
                              <Typography variant='body2'>{file.name}</Typography>
                              <Button variant='contained' color='secondary' size='small' onClick={(event) => removeFile(file, event)} startIcon={<DeleteIcon />}>
                                Eliminar
                              </Button>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                  )
                : ctx.tradeinDetails.hasFiles === 0 && ctx.displayContract === 1
                  ? (
                    <Alert severity='info'>Se requiere la firma del contrato para cargar imágenes.</Alert>
                    )
                  : <Alert severity='info'>Se requiere adjuntar imagen de capacidad de carga del dispositivo (Descuentos) para cargar imagenes.</Alert>}
            </div>
          </div>
        </div>

      </CardContent>
      {/* <CardActions style={{ justifyContent: 'flex-end' }}>
        <Button variant='contained' color='success' size='small'>
          Guardar imágenes
        </Button>
      </CardActions> */}
    </Card>
  )
}

export default Multimedia
