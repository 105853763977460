import { Card, CardContent, Grid, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { webApiService } from '../../../services'
import { useEffect, useState } from 'react'

const Questions = () => {
  const { id } = useParams()
  const [data, setData] = useState<{ loading: boolean, list: any[] }>({ loading: false, list: [] })

  const loadData = (): void => {
    setData({ ...data, loading: true })
    webApiService.getAllAnswers(String(id)).then(res => {
      setData({ loading: false, list: res.data })
    })
      .catch((error) => {
        console.log('Error loading data:', error)
      })
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <Card sx={{ borderRadius: 1 }}>
      <CardContent>
        <Typography variant='h6' component='div' mb={2} gutterBottom style={{ borderBottom: '1px solid #f3f1f1', fontSize: '18px' }}>
          Preguntas evaluación
        </Typography>
        <Grid container spacing={1}>
          {
                data.list.map((item: any, index: number) => (
                  <Grid item key={index}>
                    <p className='small mb-0'>{item.question}</p>
                    <p className='fw-bold small mb-0'>{item.answer}</p>
                  </Grid>
                ))
            }
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Questions
